import {
  Parameters,
  SortDescending,
  SortDown,
  SortUp,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  ServerErrorYellow,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import {
  Button,
  Loader,
  PopupMenu,
  SegmentedControl,
} from '@randstad-lean-mobile-factory/react-components-core';
import ReactGA from 'react-ga4';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedHash } from 'react-router-typesafe-routes/dom';
import { DistanceFilter } from 'src/Components/DistanceFilter';
import { TopBar } from 'src/Components/TopBar';
import { useFetchAgencyTemporaryWorkers } from 'src/Hooks/Candidate/useFetchAgencyTemporaryWorkers';
import { useFetchRegionalTemporaryWorkers } from 'src/Hooks/Candidate/useFetchRegionalTalents';
import { ROUTES } from 'src/Routes/Routes.types';
import { TemporaryWorkersSortingKey, TemporaryWorkersSortingMode } from 'src/Services/API';
import styles from './TemporaryWorkers.module.scss';
import { TemporaryWorkersSource, TemporaryWorkerWithStatus } from './TemporaryWorkers.types';
import { TemporaryWorkersList } from './TemporaryWorkersList';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import { FilterTalentsModal } from './TemporaryWorkersTopBarComponent';
import {
  getCurrentInterval,
  getCurrentQualifications,
  getCurrentStatuses,
} from 'src/Redux/TemporaryWorkers/Selector';
import { FilterInterval } from 'src/Redux/TemporaryWorkers/Types';
import { getTalentStatus } from './utils';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from 'src/Redux/RootReducer';
import { talentFilterActions } from 'src/Redux/TemporaryWorkers/Slice';

export const TemporaryWorkersRoute = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const { isProd } = useIsProductionEnvironment();
  const userInfo = useSelector(getAnalyticsUserInfo);

  const [distanceFilter, setDistanceFilter] = useState(60);
  const [sortingKey, setSortingKey] = useState<TemporaryWorkersSortingKey>(
    TemporaryWorkersSortingKey.availability
  );
  const [sortingMode, setSortingMode] = useState<TemporaryWorkersSortingMode>(
    TemporaryWorkersSortingMode.asc
  );
  const selectedQualifications = useSelector(getCurrentQualifications);
  const qualificationIds = selectedQualifications
    ?.map(qualification => {
      return qualification.id;
    })
    .filter(Boolean);
  const selectedInterval = useSelector(getCurrentInterval);
  const interval = selectedInterval === FilterInterval.FIFTEEN ? 15 : 7;
  const selectedStatuses = useSelector(getCurrentStatuses);

  const {
    data: agencyData,
    isLoading: agencyIsLoading,
    isFetching: agencyIsFetching,
    hasNextPage: agencyHasNextPage,
    fetchNextPage: agencyFetchNextPage,
  } = useFetchAgencyTemporaryWorkers({ sortingKey, sortingMode, qualificationIds, interval });

  const {
    data: regionalData,
    isFetching: regionalIsFetching,
    isLoading: regionalIsLoading,
    hasNextPage: regionalHasNextPage,
    fetchNextPage: regionalFetchNextPage,
  } = useFetchRegionalTemporaryWorkers({
    distanceInKm: distanceFilter,
    sortingKey,
    sortingMode,
    qualificationIds,
  });

  const [selectedSource, setSelectedSource] = useState(
    useTypedHash(ROUTES.TALENTS.TEMPORARY_WORKERS) ?? TemporaryWorkersSource.agency
  );
  const [isFilterTalentsModalOpen, setIsFilterTalentsModalOpen] = useState(false);

  let numberOfFilters =
    +Boolean(selectedSource === TemporaryWorkersSource.agency) + // interval taken into account only for my agency
    +Boolean(selectedQualifications?.length) +
    +Boolean(selectedStatuses?.length && selectedSource === TemporaryWorkersSource.agency);

  useEffect(() => {
    navigate(ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}, {}, selectedSource), { replace: true });
  }, [navigate, selectedSource]);

  const data = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyData?.pages.flatMap(page => page.temporaryWorkers);
      }
      case TemporaryWorkersSource.regional: {
        return regionalData?.pages.flatMap(page => page.temporaryWorkers);
      }
    }
  }, [agencyData, regionalData, selectedSource]);

  const filteredDataByStatus: TemporaryWorkerWithStatus[] = useMemo(() => {
    if (!data) return [];
    return data
      .map((talent: TemporaryWorkerWithStatus) => {
        const talentStatus = getTalentStatus(
          talent.lastContractEndDate,
          talent.availabilityForConsultantDate
        );
        return {
          ...talent,
          temporaryWorkerStatus: talentStatus,
        };
      })
      .filter(talent => selectedStatuses?.includes(talent.temporaryWorkerStatus));
  }, [data, selectedStatuses]);

  const sortedData = useMemo(() => {
    if (!filteredDataByStatus) return [];

    return filteredDataByStatus.sort((a, b) => {
      const dateA = a.availabilityForConsultantDate
        ? new Date(a.availabilityForConsultantDate).getTime()
        : null;
      const dateB = b.availabilityForConsultantDate
        ? new Date(b.availabilityForConsultantDate).getTime()
        : null;

      if (dateA === dateB) return 0;
      if (!dateA) return -1;
      if (!dateB) return 1;
      return dateA - dateB;
    });
  }, [filteredDataByStatus]);

  const fetchNextPage = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyFetchNextPage;
      }
      case TemporaryWorkersSource.regional: {
        return regionalFetchNextPage;
      }
    }
  }, [agencyFetchNextPage, regionalFetchNextPage, selectedSource]);

  const isLoading = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyIsLoading;
      }
      case TemporaryWorkersSource.regional: {
        return regionalIsLoading;
      }
    }
  }, [agencyIsLoading, regionalIsLoading, selectedSource]);

  const isFetching = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyIsFetching;
      }
      case TemporaryWorkersSource.regional: {
        return regionalIsFetching;
      }
    }
  }, [agencyIsFetching, regionalIsFetching, selectedSource]);

  const hasNextPage = useMemo(() => {
    switch (selectedSource) {
      case TemporaryWorkersSource.agency: {
        return agencyHasNextPage;
      }
      case TemporaryWorkersSource.regional: {
        return regionalHasNextPage;
      }
    }
  }, [agencyHasNextPage, regionalHasNextPage, selectedSource]);

  const scrollViewRef = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(() => {
    if (!scrollViewRef.current) return;
    const scrollBottom =
      scrollViewRef.current.scrollHeight -
      scrollViewRef.current.clientHeight -
      scrollViewRef.current.scrollTop;
    if (scrollBottom < 100 && !isFetching && hasNextPage) fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);
  useEffect(handleScroll, [handleScroll]);

  const resetTalentFilters = useCallback(() => {
    dispatch(talentFilterActions.reset());
  }, [dispatch]);

  return (
    <>
      <TopBar
        title={
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.values(TemporaryWorkersSource)}
            selected={selectedSource}
            getValue={source =>
              ({
                [TemporaryWorkersSource.agency]: 'intérimaires de mon unité',
                [TemporaryWorkersSource.regional]: 'intérimaires de ma région',
              }[source])
            }
            onSelectionChange={source => {
              setSelectedSource(source);
              resetTalentFilters();
              if (source === TemporaryWorkersSource.agency) {
                ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_MYTT, {
                  ...userInfo,
                });
              }
              if (source === TemporaryWorkersSource.regional) {
                ReactGA.event(ANALYTICS_EVENT.CLICK_TOGGLE_TTMYREGION, {
                  ...userInfo,
                });
              }
            }}
          />
        }
      >
        {isProd ? null : (
          <>
            {selectedSource === TemporaryWorkersSource.regional && (
              <DistanceFilter
                filteredDistance={distanceFilter}
                setFilteredDistance={setDistanceFilter}
              />
            )}

            <FilterTalentsModal
              isOpen={isFilterTalentsModalOpen}
              onClose={() => setIsFilterTalentsModalOpen(false)}
              selectedSource={selectedSource}
            />
            <Button.Tertiary.XSmall
              onClick={() => {
                setIsFilterTalentsModalOpen(true);
              }}
            >
              <Parameters />
              <div className={styles.dot}>{numberOfFilters !== 0 && numberOfFilters}</div>
            </Button.Tertiary.XSmall>

            <PopupMenu
              selectedIds={[sortingKey, sortingMode]}
              trigger={<Button.XSmall leftIcon={<SortDescending variant="line" />} />}
              position="left top"
            >
              <PopupMenu.Item
                id={TemporaryWorkersSortingMode.asc}
                icon={<SortUp size="xsmall" />}
                text="ascendant"
                onClick={() => setSortingMode(TemporaryWorkersSortingMode.asc)}
                keepOpenOnClick
              />
              <PopupMenu.Item
                id={TemporaryWorkersSortingMode.desc}
                icon={<SortDown size="xsmall" />}
                text="descendant"
                onClick={() => setSortingMode(TemporaryWorkersSortingMode.desc)}
                keepOpenOnClick
              />

              <div className={styles.menuGroup}>trier par</div>

              <PopupMenu.Item
                id={TemporaryWorkersSortingKey.agency}
                text="agence"
                onClick={() => setSortingKey(TemporaryWorkersSortingKey.agency)}
                keepOpenOnClick
              />
              <PopupMenu.Item
                id={TemporaryWorkersSortingKey.availability}
                text="prochaine dispo"
                onClick={() => setSortingKey(TemporaryWorkersSortingKey.availability)}
                keepOpenOnClick
              />
            </PopupMenu>
          </>
        )}
      </TopBar>
      <div className={styles.container}>
        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            chargement des intérimaires, cela peut prendre du temps
          </div>
        ) : !data ? (
          <div className={styles.error}>
            <ServerErrorYellow />
            Une erreur s'est produite lors du chargement des intérimaires
          </div>
        ) : data.length === 0 ? (
          <div className={styles.empty}>
            <VidePasteque />
            Aucun intérimaire ne correspond à votre recherche
          </div>
        ) : (
          <div
            style={{ flex: '1 1', minHeight: '0', overflowY: 'auto' }}
            onScroll={handleScroll}
            ref={scrollViewRef}
          >
            <TemporaryWorkersList source={selectedSource} temporaryWorkers={sortedData} />
            {isFetching && <Loader heightInRem={2} className={styles.loader} />}
          </div>
        )}
      </div>
    </>
  );
};
