import { TemporaryWorkerWithoutDetail } from 'src/Services/API';

export enum TALENTS_STATUS {
  ABSENT = 'absent',
  IN_MISSION = 'en mission',
  INTERMISSION = 'intermission',
  SOON_AVAILABLE = 'bientôt dispo',
}

export enum TemporaryWorkersSource {
  agency = 'agency',
  regional = 'regional',
}

export class TemporaryWorkerWithStatus extends TemporaryWorkerWithoutDetail {
  temporaryWorkerStatus?: TALENTS_STATUS;
}
