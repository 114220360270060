import { ThunkDispatch } from '@reduxjs/toolkit';
import { useQueryClient } from '@tanstack/react-query';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { AnyAction } from 'redux';
import { QueryKeys } from 'src/Hooks/types';
import { companiesActions } from 'src/Redux/Companies/Slice';
import { getCompanyRequestReasons } from 'src/Redux/Companies/Thunks';
import {
  getCandidateId,
  getIsFromMyRegion,
  getIsFromYouplanMission,
  getNewMissionId,
  getPositionStudiesCountSelector,
  getSelectedCompany,
  getSelectedCreationWay,
  getSelectedPreviousMission,
  isValidCompanyQualificationCouple as isValidStep1Selector,
  isValidCreationWay as isValidStep2Selector,
  isValidPreviousMissionSelected as isValidPreviousMissionSelectedSelector,
  isValidPreviousPositionStudySelected,
  isValidProcessedMission,
  getIsTalent,
  getPreviousPositionStudies,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import {
  createMissionWithoutSchedule,
  createMissionWithoutScheduleFromYouplan,
  fetchPreviousMissionDetail,
  searchPreviousMissions,
  searchPreviousPositionStudies,
} from 'src/Redux/MissionCreation/Thunks';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { RootState } from 'src/Redux/RootReducer';
import { servicesActions } from 'src/Redux/Services/Slice';
import { fetchServices } from 'src/Redux/Services/Thunks';
import { ROUTES } from 'src/Routes/Routes.types';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import MissionCreationModal from './MissionCreationModal.component';
import { pilotUnitsForMissionCreationByEDP as pilotUnits } from 'src/Routes/AppRoot/Sidebar/Sidebar.helpers';

const MemoMissionCreationModal = React.memo(MissionCreationModal);

const EnhancedMissionCreationModal = () => {
  const queryClient = useQueryClient();
  const params = useParams<{ candidateId?: string }>();
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const navigate = useNavigate();
  const newMissionId = useSelector(getNewMissionId);
  const isValidCompanyQualificationCouple = useSelector(isValidStep1Selector);
  const isValidCreationWay = useSelector(isValidStep2Selector);
  const isValidPreviousMissionSelected = useSelector(isValidPreviousMissionSelectedSelector);
  const _isValidPreviousPositionStudySelected = useSelector(isValidPreviousPositionStudySelected);
  const _isValidProcessedMission = useSelector(isValidProcessedMission);
  const selectedCreationWay = useSelector(getSelectedCreationWay);
  const selectedPreviousMission = useSelector(getSelectedPreviousMission);
  const brandCode = useSelector(getCurrentBrandCode);
  const selectedCompany = useSelector(getSelectedCompany);
  const positionStudiesCount = useSelector(getPositionStudiesCountSelector);
  const isFromYouplan = useSelector(getIsFromYouplanMission);
  const isFromMyRegion = useSelector(getIsFromMyRegion);
  const isTalent = useSelector(getIsTalent);
  const currentAgency = useSelector(getCurrentAgency);
  const isPilotUnit = currentAgency ? pilotUnits.includes(currentAgency) : false;
  const previousPositionStudies = useSelector(getPreviousPositionStudies);
  const isNonePreviousStudiesCompleted =
    positionStudiesCount === 0 ||
    (previousPositionStudies &&
      previousPositionStudies.length > 0 &&
      previousPositionStudies.every(study => study.resumeKeywords !== 'UP_TO_DATE'));
  const candidateId = useSelector(getCandidateId);
  const resetState = useCallback(() => {
    dispatch(missionCreationActions.reset());
    dispatch(missionCreationActions.resetIsFromYouplan());
    dispatch(missionCreationActions.resetIsFromMyRegion());
    dispatch(servicesActions.resetServices());
    dispatch(companiesActions.resetCompanyRequestReasons());
    queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchYouplanMissions] });
  }, [dispatch, queryClient]);
  const onClose = useCallback(
    ({ success }: { success?: boolean }) => {
      resetState();
      queryClient.invalidateQueries([QueryKeys.fetchCandidatesActivityPlanning]);
      if (candidateId !== undefined) {
        if (isTalent) {
          navigate(
            ROUTES.TALENTS.TEMPORARY_WORKERS.TEMPORARY_WORKER_DETAIL.REPOSITIONING.buildPath({
              talentId: candidateId,
            })
          );
        } else {
          navigate(ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.buildPath({ candidateId }));
        }
      } else {
        if (isFromYouplan) {
          navigate(ROUTES.ACTIVITY.YOUPLAN.buildPath({}), {
            state: { isCreationSuccess: success },
          });
        } else {
          navigate(ROUTES.ACTIVITY.MISSIONS.buildPath({}));
        }
      }
    },
    [candidateId, navigate, isFromYouplan, isTalent, queryClient, resetState]
  );
  const redirectOnMission = useCallback(() => {
    resetState();
    if (newMissionId)
      navigate(ROUTES.ACTIVITY.MISSIONS.MISSION_DETAIL.buildPath({ missionId: newMissionId }));
    else navigate(ROUTES.ACTIVITY.MISSIONS.buildPath({}));
  }, [navigate, newMissionId, resetState]);
  const _searchPreviousMissions = useCallback(() => {
    dispatch(searchPreviousMissions());
  }, [dispatch]);
  const _searchPreviousPositionStudies = useCallback(() => {
    dispatch(searchPreviousPositionStudies());
  }, [dispatch]);
  const resetSelectedPreviousMission = useCallback(() => {
    dispatch(missionCreationActions.resetSelectedPreviousMission());
  }, [dispatch]);
  const resetSelectedPreviousPositionStudy = useCallback(() => {
    dispatch(missionCreationActions.resetSelectedPreviousPositionStudy());
  }, [dispatch]);
  const resetSelectedCreationWay = useCallback(() => {
    dispatch(missionCreationActions.resetSelectedCreationWay());
  }, [dispatch]);
  const resetProcessedMission = useCallback(() => {
    dispatch(missionCreationActions.resetProcessedMission());
  }, [dispatch]);
  const setSelectedPreviousMissionDetails = useCallback(() => {
    dispatch(fetchPreviousMissionDetail(selectedPreviousMission?.missionId ?? ''));
  }, [dispatch, selectedPreviousMission]);
  const setSelectedPreviousPositionStudyForCreation = useCallback(() => {
    dispatch(missionCreationActions.setSelectedPreviousPositionStudyForNewMission());
  }, [dispatch]);
  const searchCompanyInfo = useCallback(() => {
    dispatch(
      getCompanyRequestReasons({
        brandCode: brandCode ?? ' ',
        id: selectedCompany?.companyId ?? ' ',
      })
    );
    dispatch(fetchServices({ brandCode: brandCode ?? ' ', id: selectedCompany?.companyId ?? ' ' }));
  }, [brandCode, dispatch, selectedCompany]);

  const _createMissionWithoutSchedule = useCallback(() => {
    const event =
      params.candidateId !== undefined && params.candidateId.length > 0
        ? [ANALYTICS_EVENT.REPOSITIONING_COMMAND_CREATION]
        : [
            ANALYTICS_EVENT.COMMAND_CREATION,
            isFromMyRegion && ANALYTICS_EVENT.FROM_MY_REGION_COMMAND_CREATION,
          ].filter(Boolean);
    dispatch(createMissionWithoutSchedule(event));
  }, [dispatch, isFromMyRegion, params.candidateId]);

  const _createMissionWithoutScheduleFromYouplan = useCallback(() => {
    dispatch(createMissionWithoutScheduleFromYouplan());
  }, [dispatch]);

  const setIsFromYouplan = useCallback(() => {
    dispatch(missionCreationActions.setIsFromYouplan());
  }, [dispatch]);

  const setIsFromMyRegion = useCallback(() => {
    dispatch(missionCreationActions.setIsFromMyRegion());
  }, [dispatch]);

  const setIsTalent = useCallback(() => {
    dispatch(missionCreationActions.setIsTalent());
  }, [dispatch]);

  return (
    <MemoMissionCreationModal
      onClose={onClose}
      isValidCompanyQualificationCouple={isValidCompanyQualificationCouple}
      isValidCreationWay={isValidCreationWay}
      isValidPreviousMissionSelected={isValidPreviousMissionSelected}
      isValidPreviousPositionStudySelected={_isValidPreviousPositionStudySelected}
      isValidProcessedMission={_isValidProcessedMission}
      selectedCreationWay={selectedCreationWay}
      selectedPreviousMission={selectedPreviousMission}
      positionStudiesCount={positionStudiesCount}
      isFromYouplan={isFromYouplan}
      isPilotUnit={isPilotUnit}
      isNonePreviousStudiesCompleted={isNonePreviousStudiesCompleted}
      searchPreviousMissions={_searchPreviousMissions}
      resetSelectedPreviousMission={resetSelectedPreviousMission}
      resetSelectedCreationWay={resetSelectedCreationWay}
      resetProcessedMission={resetProcessedMission}
      setSelectedPreviousMissionDetails={setSelectedPreviousMissionDetails}
      searchCompanyInfo={searchCompanyInfo}
      createMissionWithoutSchedule={_createMissionWithoutSchedule}
      resetSelectedPreviousPositionStudy={resetSelectedPreviousPositionStudy}
      searchPreviousPositionStudies={_searchPreviousPositionStudies}
      setSelectedPreviousPositionStudyForCreation={setSelectedPreviousPositionStudyForCreation}
      setIsFromYouplan={setIsFromYouplan}
      setIsFromMyRegion={setIsFromMyRegion}
      setIsTalent={setIsTalent}
      createMIssionWithoutScheduleFromYouplan={_createMissionWithoutScheduleFromYouplan}
      redirectOnMission={redirectOnMission}
    />
  );
};

export default EnhancedMissionCreationModal;
