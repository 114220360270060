import { ThunkDispatch } from '@reduxjs/toolkit';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction } from 'redux';
import {
  getPositionStudiesCountSelector,
  getPreviousPositionStudies,
  getPreviousPositionStudiesFetchStatus,
  getSelectedCompany,
  getSelectedPreviousPositionStudy,
  getSelectedQualification,
} from 'src/Redux/MissionCreation/Selectors';
import { missionCreationActions } from 'src/Redux/MissionCreation/Slice';
import { searchPreviousPositionStudies } from 'src/Redux/MissionCreation/Thunks';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { RootState } from 'src/Redux/RootReducer';
import { CompanySearchResult, PositionStudy, Qualification } from 'src/Services/API';
import PreviousPositionStudies from './PreviousPositionStudies.component';
import { pilotUnitsForMissionCreationByEDP as pilotUnits } from 'src/Routes/AppRoot/Sidebar/Sidebar.helpers';

const MemoPreviousPositionStudies = React.memo(PreviousPositionStudies);

const EnhancedPreviousPositionStudies = () => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();
  const selectedAgency = useSelector(getCurrentAgency);
  const isPilotUnit = selectedAgency ? pilotUnits.includes(selectedAgency) : false;
  const positionStudiesCount = useSelector(getPositionStudiesCountSelector);
  const selectedBrand = useSelector(getCurrentBrand);
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const previousPositionStudies = useSelector(getPreviousPositionStudies);
  const selectedPreviousPositionStudy = useSelector(getSelectedPreviousPositionStudy);
  const previousPositionStudiesFetchStatus = useSelector(getPreviousPositionStudiesFetchStatus);

  const setSelectedCompany = useCallback(
    (company?: CompanySearchResult) => {
      dispatch(missionCreationActions.setSelectedCompany(company));
      dispatch(missionCreationActions.resetSelectedPreviousPositionStudy());
      dispatch(searchPreviousPositionStudies());
    },
    [dispatch]
  );
  const setSelectedQualification = useCallback(
    (qualification?: Qualification) => {
      dispatch(missionCreationActions.setSelectedQualification(qualification));
      dispatch(missionCreationActions.resetSelectedPreviousPositionStudy());
      dispatch(searchPreviousPositionStudies());
    },
    [dispatch]
  );
  const setSelectedPreviousPositionStudy = useCallback(
    (previousPositionStudy?: PositionStudy) => {
      dispatch(missionCreationActions.setSelectedPreviousPositionStudy(previousPositionStudy));
    },
    [dispatch]
  );

  return (
    <MemoPreviousPositionStudies
      isPilotUnit={isPilotUnit}
      positionStudiesCount={positionStudiesCount}
      selectedCompany={selectedCompany}
      selectedQualification={selectedQualification}
      currentAgency={selectedAgency}
      currentBrandcode={selectedBrand}
      previousPositionStudies={previousPositionStudies}
      previousPositionStudiesFetchStatus={previousPositionStudiesFetchStatus}
      selectedPreviousPositionStudy={selectedPreviousPositionStudy}
      setSelectedCompany={setSelectedCompany}
      setSelectedQualification={setSelectedQualification}
      setSelectedPreviousPositionStudy={setSelectedPreviousPositionStudy}
    />
  );
};

export default EnhancedPreviousPositionStudies;
