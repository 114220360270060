import { useEffect, useState } from 'react';

import { Loader, SegmentedControl } from '@randstad-lean-mobile-factory/react-components-core';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useFetchExternalDemands } from 'src/Hooks/ExternalDemands/useFetchExternalDemands';
import { getSearchInput } from 'src/Redux/ExternalDemandList/Selectors';
import { getSelectedSource } from 'src/Redux/ExternalDemandPreferences/Selectors';
import { ExternalDemandPreferencesActions } from 'src/Redux/ExternalDemandPreferences/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import { AppelMedicalEventSource, DisplayStatus } from 'src/Services/API';
import { TopBar } from 'src/Components/TopBar';
import styles from './DemandsList.module.scss';
import ExternalDemandsList from './ExternalDemandsList';
import ExternalDemandsTopBarComponent from 'src/Components/TopBar/ExternalDemandsTopBarComponent/ExternalDemandsTopBarComponent.component';
import { getCurrentAgency } from '../../../Redux/Perimeter/Selectors';
import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';
import { columnFiltersToGetCommandsParams, sortingStateToGetCommandParams } from './transformers';
import { ExternalDemandsListCounterBadge } from '../../../Components/ExternalDemandsListCounterBadge/ExternalDemandsListCounterBadge.component';
import { ExternalDemandListActions } from '../../../Redux/ExternalDemandList/Slice';

export const DemandsListRoute = () => {
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);
  const initialSource = useSelector(getSelectedSource);
  const searchInput = useSelector(getSearchInput);
  const dispatch = useDispatch();
  const agencyId = useSelector(getCurrentAgency) ?? '';

  const displayStatus = showArchivedDemands ? DisplayStatus.archived : DisplayStatus.displayable;
  const [selectedSource, setSelectedSource] = useState<AppelMedicalEventSource>(initialSource);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [pagination, setPagination] = useState<PaginationState>({ pageSize: 20, pageIndex: 0 });
  const [sorting, setSorting] = useState<SortingState>([]);

  const { data, isSuccess, isLoading, isFetching } = useFetchExternalDemands({
    displayStatus,
    agencyId,
    source: selectedSource,
    ...columnFiltersToGetCommandsParams(columnFilters),
    ...sortingStateToGetCommandParams(sorting),
    limit: pagination.pageSize,
    page: pagination.pageIndex,
  });

  useEffect(() => {
    dispatch(
      ExternalDemandListActions.setColumnFilters({
        displayStatus,
        agencyId,
        source: selectedSource,
        ...columnFiltersToGetCommandsParams(columnFilters),
      })
    );
  }, [agencyId, columnFilters, dispatch, displayStatus, selectedSource]);

  return (
    <>
      <div className={styles.container}>
        <TopBar title={showArchivedDemands ? 'demandes archivées' : 'demandes'}>
          <ExternalDemandsTopBarComponent />
        </TopBar>
        <div className={styles.segmentedControlContainer}>
          <SegmentedControl
            className={styles.segmentedControl}
            controls={[AppelMedicalEventSource.ARTEMIHS, AppelMedicalEventSource.HUBLO]}
            selected={selectedSource}
            getValue={(dashboardMenuItem: AppelMedicalEventSource) => {
              return (
                <div className={styles.segmentContent}>
                  {dashboardMenuItem.toLowerCase()}
                  <ExternalDemandsListCounterBadge
                    isSelected={dashboardMenuItem === selectedSource}
                    source={dashboardMenuItem}
                    displayStatus={
                      !showArchivedDemands ? DisplayStatus.displayable : DisplayStatus.archived
                    }
                  />
                </div>
              );
            }}
            onSelectionChange={dashboardMenuItem => {
              setSelectedSource(dashboardMenuItem);
              dispatch(ExternalDemandPreferencesActions.setSelectedSource(dashboardMenuItem));
            }}
          />
        </div>
        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            <div>chargement des demandes externes, cela peut prendre du temps</div>
          </div>
        ) : !isSuccess ? (
          <div className={styles.error}>
            Une erreur s'est produite pendant la recherche de commandes
          </div>
        ) : data?.count === 0 ? (
          <div className={styles.noResult}>aucun résultat pour cette agence</div>
        ) : (
          <ExternalDemandsList
            data={data?.demands}
            count={data?.count}
            isFetching={isFetching}
            searchInput={searchInput}
            source={selectedSource}
            filters={columnFilters}
            setFilters={setColumnFilters}
            setPagination={setPagination}
            pagination={pagination}
            setSorting={setSorting}
            sorting={sorting}
          />
        )}
      </div>
    </>
  );
};
