import { Folder } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, TypeAndEnter } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch, useNavigate } from 'react-router-dom';
import { ExportIcon } from 'src/Assets_DEPRECATED';
import { useExportExternalDemands } from 'src/Hooks/ExternalDemands/useExportExternalDemands';
import { ExternalDemandListActions } from 'src/Redux/ExternalDemandList/Slice';
import { ROUTES } from 'src/Routes/Routes.types';
import SearchButton from '../SearchButton';
import styles from './ExternalDemandsTopBarComponent.module.scss';
import { getCurrentAgency } from '../../../Redux/Perimeter/Selectors';
import { useAuthenticatedUser } from '../../../Hooks/Authentication/useAuthenticatedUser';
import { getSelectedSource } from '../../../Redux/ExternalDemandPreferences/Selectors';

const ExternalDemandsTopBarComponent = () => {
  const navigate = useNavigate();
  const showArchivedDemands = !!useMatch(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.path);

  const dispatch = useDispatch();

  const agencyId = useSelector(getCurrentAgency) ?? '';
  const user = useAuthenticatedUser();
  const source = useSelector(getSelectedSource);

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState('');

  const searchBarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target as Node)) {
        setSearchOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [searchBarRef]);

  useEffect(() => {
    dispatch(ExternalDemandListActions.setSearchInput(searchValue));
  }, [dispatch, searchValue]);
  const exportExternalDemands = useExportExternalDemands();
  return (
    <div className={styles.container}>
      <Button.Secondary
        className={styles.demandsButtonContainer}
        onClick={() => {
          if (showArchivedDemands) {
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({}));
          } else {
            navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.ARCHIVED.buildPath({}));
          }
        }}
      >
        <Folder className={styles.folderIcon} />
        {showArchivedDemands ? 'demandes non archivées' : 'demandes archivées'}
      </Button.Secondary>
      <Button.Primary
        className={styles.demandsButtonContainer}
        onClick={() => exportExternalDemands.mutate({ agencyId, user, source })}
      >
        <ExportIcon className={styles.folderIcon} /> exporter
      </Button.Primary>
      <div className={styles.searchContainer}>
        {searchOpen || Boolean(searchValue) ? (
          <div className={styles.searchBar} ref={searchBarRef}>
            <TypeAndEnter
              selectedValue={searchValue}
              setSelectedValue={newValue => setSearchValue(newValue)}
              placeholder="rechercher"
              textInputContainerClassName={styles.textInputContainerClassName}
              hideReinitialisation={true}
            />
          </div>
        ) : (
          <SearchButton searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        )}
      </div>
    </div>
  );
};
export default ExternalDemandsTopBarComponent;
