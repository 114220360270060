import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import { Button } from '@randstad-lean-mobile-factory/react-components-core';
import useAuth from 'src/Hooks/Authentication/useAuth';
import Styles from './Login.module.scss';

export const Login = () => {
  const { isLoading, loginWithRedirect, error } = useAuth();

  return (
    <div className={Styles.Container} data-theme="dark">
      <div className={Styles.Content}>
        <Flash size="large" />
        <div className={Styles.Title}>
          Bienvenue
          <br />
          sur la nouvelle version flash
        </div>
        <div className={Styles.Description}>votre service online de suivi de votre activité</div>
        {error && (
          <>
            <div className={Styles.Error}>
              Une erreur s'est produite lors de l'authentification. Si l'erreur persiste, contactez
              l'assistance.
            </div>
            <div className={Styles.Error}>
              Message d'erreur :
              <br />
              {error.message}
            </div>
          </>
        )}
        <Button
          className={Styles.Button}
          onClick={() => loginWithRedirect({ appState: { returnTo: window.location.href } })}
          mutationStatus={isLoading ? 'loading' : undefined}
        >
          se connecter
        </Button>
      </div>
    </div>
  );
};
