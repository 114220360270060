import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FilterInterval, TalentFilterState } from './Types';
import { CDII_STATUS } from 'src/Routes/Talents/Cdii/Cdii.types';

export const initialState: TalentFilterState = {
  currentQualifications: [],
  currentName: '',
  currentStatuses: [CDII_STATUS.INTERMISSION, CDII_STATUS.SOON_AVAILABLE],
  currentInterval: FilterInterval.SEVEN,
};

export const { reducer: talentFilterReducer, actions: talentFilterActions } = createSlice({
  name: 'talentFilter',
  initialState,
  reducers: {
    setCurrentQualifications: (
      state,
      action: PayloadAction<TalentFilterState['currentQualifications']>
    ) => {
      state.currentQualifications = action.payload;
    },
    setCurrentName: (state, action: PayloadAction<TalentFilterState['currentName']>) => {
      state.currentName = action.payload;
    },
    setCurrentStatuses: (state, action: PayloadAction<TalentFilterState['currentStatuses']>) => {
      state.currentStatuses = action.payload;
    },
    setCurrentInterval: (state, action: PayloadAction<TalentFilterState['currentInterval']>) => {
      state.currentInterval = action.payload;
    },
    reset: () => initialState,
  },
});
