const isVariableSimulatorDebugEnabled =
  process.env.REACT_APP_FEATURE_SWITCH_VARIABLE_SIMULATOR_DEBUG_ENABLED === 'true';
const canUseRepositioning = process.env.REACT_APP_FEATURE_SWITCH_REPOSITIONING === 'true';
const canUseRepositioningCandidats =
  process.env.REACT_APP_FEATURE_SWITCH_REPOSITIONING_CANDIDATS === 'true';
const canUseAccidentologie = process.env.REACT_APP_FEATURE_SWITCH_ACCIDENTOLOGIE === 'true';
const canUseTalents = process.env.REACT_APP_FEATURE_SWITCH_TALENT === 'true';

export const FeatureSwitchManager = {
  isVariableSimulatorDebugEnabled,
  canUseRepositioning,
  canUseRepositioningCandidats,
  canUseAccidentologie,
  canUseTalents,
};
