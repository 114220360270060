import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

import { getCurrentQualification } from 'src/Redux/Qualifications/Selectors';
import { qualificationsActions } from 'src/Redux/Qualifications/Slice';

import { getCurrentCompany } from 'src/Redux/Companies/Selectors';
import { companiesActions } from 'src/Redux/Companies/Slice';
import { useSingleDatePicker } from 'src/Redux/Search/Hooks';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { RootState } from 'src/Redux/RootReducer';
import { getSelectedSalesphases } from 'src/Redux/Search/Selectors';
import { searchActions } from 'src/Redux/Search/Slice';
import { getCurrentService } from 'src/Redux/Services/Selectors';
import { servicesActions } from 'src/Redux/Services/Slice';
import { fetchServices } from 'src/Redux/Services/Thunks';
import {
  CompanySearchResult,
  CompanyService,
  OSMCandidateModelSearchMultiCriteriaDtoCandidateSearchMultiCriteriaDto,
  Qualification,
  Salesphase,
} from 'src/Services/API';
import FilterMissionsModal from './FilterMissionsModal.component';
import { Props } from './FilterMissionsModal.enhanced.types';
import { CDICandidatesActions } from 'src/Redux/CDICandidates/Slice';
import { getCurrentCandidate } from 'src/Redux/CDICandidates/Selectors';

const MemoFilterMissionsModal = React.memo(FilterMissionsModal);

const EnhancedFilterMissionsModal = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  // Perimeter Selection
  const selectedAgency = useSelector(getCurrentAgency);
  const selectedBrand = useSelector(getCurrentBrand);

  // Qualification Selection
  const selectedQualification = useSelector(getCurrentQualification);
  const setSelectedQualification = useCallback(
    (qualification?: Qualification) =>
      dispatch(qualificationsActions.setCurrentQualification(qualification)),
    [dispatch]
  );

  // Company Selection
  const selectedCompany = useSelector(getCurrentCompany);
  const setSelectedCompany = useCallback(
    (company?: CompanySearchResult) => dispatch(companiesActions.setCurrentCompany(company)),
    [dispatch]
  );
  const onCompanySelection = useCallback(
    (companyId: string, brandCode: string) => dispatch(fetchServices({ id: companyId, brandCode })),
    [dispatch]
  );

  // Candidate Selection
  const selectedCandidate = useSelector(getCurrentCandidate);
  const setSelectedCandidate = useCallback(
    (candidate?: OSMCandidateModelSearchMultiCriteriaDtoCandidateSearchMultiCriteriaDto) =>
      dispatch(CDICandidatesActions.setCurrentCandidate(candidate)),
    [dispatch]
  );

  // Service Selection
  const selectedService = useSelector(getCurrentService);
  const setSelectedService = useCallback(
    (company?: CompanyService) => dispatch(servicesActions.setCurrentService(company)),
    [dispatch]
  );

  // Salesphases Selection
  const selectedSalesphases = useSelector(getSelectedSalesphases);
  const setSelectedSalesphases = useCallback(
    (salesphases: Salesphase[]) => dispatch(searchActions.setSalesphases(salesphases)),
    [dispatch]
  );

  // Single Date Selection
  const [date, onDateChange] = useSingleDatePicker();

  return (
    <MemoFilterMissionsModal
      selectedBrand={selectedBrand}
      selectedAgency={selectedAgency}
      selectedQualification={selectedQualification}
      setSelectedQualification={setSelectedQualification}
      selectedCompany={selectedCompany}
      setSelectedCompany={setSelectedCompany}
      onCompanySelection={onCompanySelection}
      selectedCandidate={selectedCandidate}
      setSelectedCandidate={setSelectedCandidate}
      selectedService={selectedService}
      setSelectedService={setSelectedService}
      selectedSalesphases={selectedSalesphases}
      setSelectedSalesphases={setSelectedSalesphases}
      date={date}
      onDateChange={onDateChange}
      onValidateClick={onClose}
      onCancelClick={onClose}
      open={isOpen}
    />
  );
};

export default EnhancedFilterMissionsModal;
