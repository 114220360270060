import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExternalDemandListState } from './Types';
import { GetCommandsParamsDto } from '../../Services/API';

const initialState: ExternalDemandListState = {
  searchInput: '',
  columnFilters: {},
};

export const {
  reducer: ExternalDemandListReducer,
  actions: ExternalDemandListActions,
} = createSlice({
  name: 'ExternalDemandList',
  initialState,
  reducers: {
    setSearchInput: (state, action: PayloadAction<string>) => {
      state.searchInput = action.payload;
    },
    setColumnFilters: (state, action: PayloadAction<GetCommandsParamsDto>) => {
      state.columnFilters = action.payload;
    },
  },
});
