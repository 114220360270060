import { useInfiniteQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { SEARCH_LIMIT } from 'src/Redux/Search/Types';
import { MissionsService, SearchMissionsBody } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchMissions = (
  params: Omit<SearchMissionsBody, 'limit' | 'offset' | 'brandCode' | 'agencyId' | 'search'>
) => {
  const brandCode = useSelector(getCurrentBrandCode) ?? '';
  const agencyId = useSelector(getCurrentAgency) ?? '';

  return useInfiniteQuery(
    [QueryKeys.fetchMissions, brandCode, agencyId, params],
    async ({ pageParam = 0 }) => {
      const { missions } = await MissionsService.missionsControllerGetMissions({
        body: {
          brandCode,
          agencyIds: [agencyId],
          offset: pageParam * SEARCH_LIMIT,
          limit: SEARCH_LIMIT,
          search: true,
          ...params,
        },
      });

      const totalMissions = missions?.length ?? 0;

      if (!missions) return { missions: [], totalMissions, nextPage: undefined };

      return {
        missions,
        totalMissions,
        nextPage: missions.length === SEARCH_LIMIT ? pageParam + 1 : undefined,
      };
    },
    {
      getNextPageParam: page => page.nextPage,
    }
  );
};
