import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { CandidateMission, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';
import moment from 'moment';

export const useFetchCandidateMissions = (
  id: string,
  extraOptions?: UseQueryOptions<CandidateMission[], unknown, CandidateMission[], string[]>
) => {
  return useQuery(
    [QueryKeys.fetchMissionsCandidate, id],
    async () => {
      return await CandidatesService.candidatesControllerGetMissionsCandidate({
        body: {
          candidateId: id,
          startDate: moment().subtract(2, 'days').toDate(),
          endDate: moment().add(30, 'days').toDate(),
        },
      });
    },
    { enabled: id !== undefined && id !== '', ...extraOptions }
  );
};
