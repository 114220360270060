import { useCallback, useEffect, useState } from 'react';
import { Props } from './FilterTalentsModal.types';
import { CDII_STATUS } from '../../Cdii/Cdii.types';
import { FilterInterval } from 'src/Redux/TemporaryWorkers/Types';
import { Filtres } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Badge,
  Button,
  Checkbox,
  Modal,
  ModalActions,
  ModalContent,
  Radio,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import styles from './FilterTalentsModal.module.scss';
import SelectMultipleQualifications from 'src/Components/Selects/SelectMultipleQualifications';
import { initialState } from 'src/Redux/TemporaryWorkers/Slice';
import { TemporaryWorkersSource } from '../TemporaryWorkers.types';

const FilterTalentsModal = ({
  selectedQualifications,
  setSelectedQualifications,
  selectedStatuses,
  setSelectedStatuses,
  selectedInterval,
  setSelectedInterval,
  onValidateClick,
  onCancelClick,
  open,
  selectedSource,
}: Props) => {
  const [qualifications, setQualifications] = useState(selectedQualifications);
  const [statuses, setStatuses] = useState<CDII_STATUS[] | undefined>(selectedStatuses);
  const [interval, setInterval] = useState<FilterInterval>(
    selectedInterval ?? FilterInterval.SEVEN
  );

  useEffect(() => {
    setQualifications(selectedQualifications);
    setStatuses(selectedStatuses);
    setInterval(selectedInterval ?? FilterInterval.SEVEN);
  }, [selectedQualifications, selectedStatuses, selectedInterval]);

  const reset = useCallback(() => {
    setQualifications(initialState.currentQualifications);
    setStatuses(initialState.currentStatuses);
    setInterval(initialState.currentInterval);
  }, []);

  const saveFilters = useCallback(() => {
    setSelectedQualifications(qualifications);
    setSelectedStatuses(statuses);
    setSelectedInterval(interval);
    onValidateClick();
  }, [
    interval,
    onValidateClick,
    qualifications,
    setSelectedInterval,
    setSelectedQualifications,
    setSelectedStatuses,
    statuses,
  ]);

  const handleStatusChange = (status: CDII_STATUS) => (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setStatuses(prevStatuses => [...(prevStatuses ?? []), status]);
    } else {
      setStatuses(prevStatuses => prevStatuses?.filter(s => s !== status) ?? []);
    }
  };

  return (
    <Modal size="medium" open={open ?? true} onClose={onCancelClick}>
      <ModalContent header={<Filtres />} title="filtres talents">
        <ul className={styles.list}>
          <li>
            <WithLightTitle title="qualifications principales" className={styles.filter}>
              <SelectMultipleQualifications
                // We specify no society / brand to have the same behavior as OSM
                canBeReset
                selectedQualifications={qualifications}
                setSelectedQualifications={selectedQualifications => {
                  setQualifications(selectedQualifications);
                }}
              />
            </WithLightTitle>
          </li>
          {selectedSource === TemporaryWorkersSource.agency && (
            <>
              <li>
                <WithLightTitle title="à repositionner" className={styles.filter}>
                  <div className={styles.radioButtons}>
                    <Radio
                      checked={interval === FilterInterval.SEVEN}
                      onChange={() => setInterval(FilterInterval.SEVEN)}
                    >
                      dates de dispo comprises entre J-7 et J+7 depuis date du jour
                    </Radio>
                    <Radio
                      checked={interval === FilterInterval.FIFTEEN}
                      onChange={() => setInterval(FilterInterval.FIFTEEN)}
                    >
                      dates de dispo comprises entre J-15 et J+15 depuis date du jour
                    </Radio>
                  </div>
                </WithLightTitle>
              </li>

              <li>
                <WithLightTitle title="statut du talent" className={styles.filter}>
                  <div className={styles.checkboxGrid}>
                    <Checkbox
                      size={2}
                      checked={statuses?.includes(CDII_STATUS.MISSION)}
                      onChange={handleStatusChange(CDII_STATUS.MISSION)}
                    >
                      <Badge color="success" children="en mission" />
                    </Checkbox>
                    <Checkbox
                      checked={statuses?.includes(CDII_STATUS.INTERMISSION)}
                      onChange={handleStatusChange(CDII_STATUS.INTERMISSION)}
                    >
                      <Badge color="error" children="intermission" />
                    </Checkbox>
                    <Checkbox
                      checked={statuses?.includes(CDII_STATUS.SOON_AVAILABLE)}
                      onChange={handleStatusChange(CDII_STATUS.SOON_AVAILABLE)}
                    >
                      <Badge color="warning" children="bientôt dispo" />
                    </Checkbox>
                    <Checkbox
                      checked={statuses?.includes(CDII_STATUS.ABSENT)}
                      onChange={handleStatusChange(CDII_STATUS.ABSENT)}
                    >
                      <Badge color="navy" children="absent" />
                    </Checkbox>
                  </div>
                </WithLightTitle>
              </li>
            </>
          )}
        </ul>
      </ModalContent>

      <ModalActions side="left">
        <Button.Tertiary onClick={reset}>tout réinitialiser</Button.Tertiary>
      </ModalActions>
      <ModalActions side="right">
        <Button.Secondary onClick={onCancelClick}>annuler</Button.Secondary>
        <Button.Primary onClick={saveFilters}>valider</Button.Primary>
      </ModalActions>
    </Modal>
  );
};

export default FilterTalentsModal;
