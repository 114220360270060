export const appelMedicalBrandCodes = ['307AM', '307AMS', '307JBM'];

export const cdiiRepositioningAgencies = [
  'AR3',
  'BE4',
  'DVO',
  'VSF',
  '804',
  'MIM',
  'ZD',
  'SMI',
  'HN',
  'GDX',
  'PA4',
  'PB',
  'ACR',
  'ELA',
  '698',
  'MG',
  'DY',
  'FIG',
  'CW',
  'MCF',
  'AT',
  'FCV',
  'NVM',
  'IO',
  'JZW',
  'ZW',
  'CBO',
  'QB',
  'MM',
  'LBA',
  'AR',
  'OR8',
  'NB',
  'V94',
  '854',
  'USS',
  'MAR',
  'V79',
  'ORT',
  'VC6',
  'JI',
  'VIS',
  'OB3',
  'HAU',
  'CCW',
  'CCH',
  'GH',
  'JT',
  'PAU',
  'NU2',
  'OI1',
  'SJ',
  'FCE',
  'AJO',
  'VS6',
  'THI',
  'MY',
  'VLU',
  'CLU',
  'ACT',
  'TCF',
  'RBX',
  'ZG',
  'TUL',
  'ZQ',
  'ICF',
  'NV2',
  'FVA',
  'TGH',
  'CAU',
  'CF',
  'TAR',
  'ZB',
  'V95',
  'AT2',
  'KPO',
  'LA',
  'ZN',
  'AUR',
  '849',
  'ZJ',
  'JSB',
  'LPC',
  'IZN',
  'BC',
  'XV',
  'LOU',
  'AE1',
  'VY',
  '693',
  'BIG',
  'OCF',
  'ZQ2',
  'LG',
  'BRD',
  'AE',
  'BAC',
  'PS9',
  'FSA',
  'CMM',
  'LPA',
  'LPC',
  'PAX',
  'MG',
  'XV',
  'FJU',
  'DCM',
  'AIA',
  'AAY',
  'CCN',
  'CLO',
  'CGR',
  'CCA',
  '306',
  '308',
  '310',
  '362',
  '376',
  '379',
  'BAN',
  'BSN',
  'DAG',
  'IRZ',
  'KN',
  'LIG',
  'MA6',
  'MA7',
  'MA8',
  'MHF',
  'NAM',
  'NAX',
  'NEB',
  'ONA',
  'QP',
  'SKC',
  'SPG',
  'T36',
  'TW',
  'V58',
  'V97',
  'VAH',
  'VAK',
  'ANP',
  'BS',
  'BS1',
  'BSB',
  'CAP',
  'CJ',
  'GLO',
  'GO',
  'GP',
  'GU2',
  'GUI',
  'HLO',
  'IZO',
  'LNO',
  'LT',
  'NW',
  'OL3',
  'OL4',
  'PDO',
  'PLO',
  'PST',
  'QO',
  'QY',
  'RW',
  'SB',
  'V38',
  'VAG',
  'VAI',
  'VAL',
  'ZI',
  'ZK',
  'ZO',
  'ZR',
  'ZRI',
  '385',
  '386',
  'BDB',
  'BRE',
  'DBR',
  'DPL',
  'FBO',
  'FCH',
  'FMO',
  'IZL',
  'IZX',
  'MBG',
  'MDB',
  'OC3',
  'OSR',
  'RS',
  'RSX',
  'STI',
  'TRS',
  'V59',
  'VAD',
  'ZL',
  'ZU',
  'ZX',
  'ZXA',
  'TIL',
  'BOT',
  'AGS',
  'AGT',
  'ARY',
  'BBA',
  'BLV',
  'CHG',
  'CHI',
  'CNC',
  'CSY',
  'E12',
  'ECH',
  'ERY',
  'ESR',
  'ESY',
  'EVR',
  'FCG',
  'GSL',
  'IAG',
  'IBE',
  'ILL',
  'JS',
  'LEA',
  'LVJ',
  'MFC',
  'OLR',
  'OLS',
  'OY',
  'PIL',
  'QX',
  'RCC',
  'RMA',
  'RO',
  'RSY',
  'SAN',
  'SAO',
  'SLR',
  'SMY',
  'TSA',
  'TU',
  'V35',
  'VEC',
  'VL6',
  'ZF',
  'DA',
  'BRO',
  '300',
  '301',
  '344',
  '741',
  'COC',
  'DI2',
  'E27',
  'EPC',
  'FLO',
  'GPH',
  'IB',
  'JVR',
  'KCS',
  'LH',
  'LHX',
  'MFM',
  'OC2',
  'OS3',
  'PR',
  'PSP',
  'QG',
  'RC',
  'RG',
  'RY',
  'SCE',
  'SV1',
  'VEO',
  'VNE',
  'VPK',
  'XSV',
  'YG',
  'YIE',
  'YO',
  'YX',
  'YZ',
  '327',
  '332',
  '333',
  '953',
  'CG',
  'CGM',
  'CN2',
  'CNX',
  'CO',
  'DOV',
  'E52',
  'OA1',
  'FMT',
  'FVI',
  'KE',
  'LFA',
  'LFM',
  'LSA',
  'LX',
  'MVI',
  'NKG',
  'NZA',
  'OL2',
  'QK',
  'RB',
  'VMO',
  'YP',
  '108',
  '136',
  '536',
  '817',
  '860',
  '921',
  '960',
  'BG',
  'BL',
  'CHL',
  'CSC',
  'CT',
  'CT3',
  'CTY',
  'E29',
  'EDX',
  'FTH',
  'GCH',
  'GOR',
  'HMO',
  'IDR',
  'IE',
  'IF',
  'IOR',
  'JR',
  'LLB',
  'LTO',
  'MK',
  'PVZ',
  'SGS',
  'SUL',
  'SUN',
  'TJS',
  'TO',
  'TOG',
  'TSB',
  'V32',
  'VBL',
  'VIE',
  'VV',
  'AS6',
  'BSA',
  'ERO',
  'FBR',
  'FR',
  'GAR',
  'GVP',
  'IJA',
  'JA',
  'LM1',
  'LME',
  'NAL',
  'PAR',
  'QJ',
  'QU',
  'SOC',
  'TKT',
  'VNR',
  'VSU',
  'CCC',
  'CVR',
  'CCB',
  'CCM',
  'TIS',
  'KLO',
  'BRX',
  'ICY',
  'TSL',
  'CDP',
  'NCI',
  'TA2',
  'TC',
  'CCS',
  'CGG',
];

export const temporaryWorkersRepositioningAgencies = [
  'ACT',
  'CW',
  'FCV',
  'KPO',
  'PB',
  'SMI',
  'V95',
  'VC6',
  '693',
  '698',
  'CF',
  'DVO',
  'ICF',
  'JSB',
  'MCF',
  'OCF',
  'OI1',
  'OR8',
  'TCF',
  'VIS',
  'VS6',
  'THI',
  'JI',
  '804',
  '849',
  '854',
  'BIG',
  'BRD',
  'CBO',
  'CLU',
  'FVA',
  'GH',
  'LBA',
  'LPC',
  'RBX',
  'TGH',
  'ZG',
  'GDX',
];

export const pilotUnitsForMissionCreationByEDP = ['RC', '741', 'AT2', 'V94'];
