import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { QueryKeys } from '../types';
import { ExternalDemandsService, GetCommandsParamsDto } from 'src/Services/API';

export const useFetchExternalDemands = (params: GetCommandsParamsDto) => {
  const agencyId = useSelector(getCurrentAgency) ?? '';
  return useQuery(
    [QueryKeys.fetchExternalDemands, agencyId, params],
    async () => {
      return ExternalDemandsService.externalDemandsControllerGetExternalDemand({
        body: {
          ...params,
        },
      });
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
};
