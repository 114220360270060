import {
  ExternalDemandElement,
  isDemandItem,
} from './ExternalDemandsList/ExternalDemandsList.types';
import {
  AppelMedicalEventStatus,
  ExternalDemandStatus,
  GetCommandsParamsDto,
  MongoSortingDirection,
  SortingOptionsDto,
  SortingValues,
} from '../../../Services/API';

import {
  ANNULATION_ERROR,
  CANCEL_ERROR,
  ROULEMENT_ERROR,
  TECHNICAL_ERROR,
} from './ExternalDemandsListMenu/ExternalDemandsListMenu.types';
import { ColumnFiltersState, SortingState } from '@tanstack/react-table';

const causeTransformer = (text?: string) => {
  if (!text) return "Contacter l'assistance";
  if (text.includes(ROULEMENT_ERROR)) return 'Un roulement est déja positionné pour ce jour';
  if (text.includes(TECHNICAL_ERROR)) return 'Problème Osmose';
  if (text.includes(CANCEL_ERROR)) return CANCEL_ERROR;
  if (text.length > 40) return "Contacter l'assistance";
  if (text.includes(ANNULATION_ERROR))
    return "erreur d'annulation: candidats non M présents, merci de les supprimer depuis osmose";
  return text;
};

export const formatError = (element: ExternalDemandElement) => {
  if (element.status === AppelMedicalEventStatus.Ignorée) return 'Ignorée';
  if (isDemandItem(element)) {
    if (
      element.status === AppelMedicalEventStatus['Commande créée'] &&
      element.cause !== CANCEL_ERROR
    )
      return 'Roulement créé';
    if (element.status === AppelMedicalEventStatus['Commande mise à jour'])
      return 'Roulement mis à jour ';
    return causeTransformer(element.cause);
  } else {
    if (element.status === ExternalDemandStatus.COMPLETE) return 'Commande créée';
    if (element.status === ExternalDemandStatus.MIXED) return 'Des erreurs se sont produites';
    return element.items.length > 1
      ? 'Consulter les roulements'
      : causeTransformer(element.items[0].cause);
  }
};

export const columnFiltersToGetCommandsParams = (
  columnFilters: ColumnFiltersState
): Omit<GetCommandsParamsDto, 'agencyId' | 'source'> => {
  const data = columnFilters as {
    id: string;
    value: string[] | string;
  }[];

  return data.reduce((acc, actual) => {
    if (actual.id === 'missionDate' && actual.value.length === 2) {
      acc['missionStartDate'] = new Date(actual.value[0]).toISOString();
      acc['missionEndDate'] = new Date(actual.value[1]).toISOString();
    } else if (actual.id === 'limitDate' && actual.value.length === 2) {
      acc['limitStartDate'] = new Date(actual.value[0]).toISOString();
      acc['limitEndDate'] = new Date(actual.value[1]).toISOString();
    } else {
      acc[actual.id] = actual.value;
    }
    return acc;
  }, {} as { [key: string]: string[] | string });
};

export const sortingStateToGetCommandParams = (state: SortingState): SortingOptionsDto[] => {
  const data = state as { desc: boolean; id: string }[];

  return data.map(sortingElement => {
    return {
      key:
        Object.values(SortingValues).find(value => sortingElement.id === value) ??
        SortingValues.missionDate,
      direction: sortingElement.desc ? MongoSortingDirection.desc : MongoSortingDirection.asc,
    };
  });
};
