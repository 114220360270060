import { combineReducers } from '@reduxjs/toolkit';
import { missionsReducer } from 'src/Redux/Missions/Slice';
import { CDICandidatesReducer } from './CDICandidates/Slice';
import { companiesReducer } from './Companies/Slice';
import { contractsReducer } from './Contracts/Slice';
import { missionCreationReducer } from './MissionCreation/Slice';
import { perimeterReducer } from './Perimeter/Slice';
import { qualificationsReducer } from './Qualifications/Slice';
import { searchReducer } from './Search/Slice';
import { servicesReducer } from './Services/Slice';
import { AppDispatch } from './store';
import { variableSimulatorReducer } from './VariableSimulator/Slice';
import { youplanCompaniesReducer } from './YouplanCompanies/Slice';
import { ExternalDemandPreferencesReducer } from './ExternalDemandPreferences/Slice';
import { ExternalDemandListReducer } from './ExternalDemandList/Slice';
import { talentFilterReducer } from './TemporaryWorkers/Slice';

const rootReducer = combineReducers({
  companies: companiesReducer,
  contracts: contractsReducer,
  perimeter: perimeterReducer,
  qualifications: qualificationsReducer,
  talentFilter: talentFilterReducer,
  search: searchReducer,
  services: servicesReducer,
  youplanCompanies: youplanCompaniesReducer,
  missionCreation: missionCreationReducer,
  variableSimulator: variableSimulatorReducer,
  missions: missionsReducer,
  CDICandidates: CDICandidatesReducer,
  ExternalDemandPreferences: ExternalDemandPreferencesReducer,
  ExternalDemandList: ExternalDemandListReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export interface ThunkAPI {
  dispatch: AppDispatch;
  state: RootState;
  extra?: unknown;
  rejectValue?: unknown;
}

export default rootReducer;
