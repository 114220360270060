import { BadgeDeprecated, Checkbox } from '@randstad-lean-mobile-factory/react-components-core';
import styles from './RepositioningCard.module.scss';
import { Props } from './RepositioningCard.types';
import moment from 'moment';
import classnames from 'classnames';
import React from 'react';

const RepositioningCard = ({
  companyName,
  serviceName,
  qualificationName,
  startDate,
  agencyId,
  status,
  isChecked,
  isSelected,
  onCheckBoxClick,
  onClick,
  flag,
  badgeClassName,
  isSkillMatching,
  skillMatchingMatchScore,
}: Props) => (
  <div
    className={classnames(styles.container, { [styles.isSelected]: isChecked || isSelected })}
    onClick={onClick}
  >
    <Checkbox checked={isChecked} onClick={onCheckBoxClick} onKeyDown={onCheckBoxClick} />
    <div className={styles.infoContainer}>
      {isSkillMatching && (
        <div className={styles.skillMatchingMatchScore}>{skillMatchingMatchScore}%</div>
      )}
      <p className={styles.companyName}>{companyName}</p>
      {serviceName && <p className={styles.serviceName}>{serviceName}</p>}
      {flag && (
        <div className={classnames(styles.flag, flag.className)}>{`${flag.count ?? '??'} ${
          flag.label
        }`}</div>
      )}
      <div className={styles.separator} />
      <p className={styles.qualification}>{qualificationName}</p>
      <p className={styles.otherInformation}>{`début commande ${moment(startDate).format('L')} ${
        agencyId ? `| ${agencyId}` : ''
      }`}</p>
      {status && (
        <BadgeDeprecated
          value={
            status.toLocaleLowerCase() === 'a servir' ? 'à servir' : status.toLocaleLowerCase()
          }
          className={
            badgeClassName
              ? badgeClassName
              : status === 'A servir'
              ? styles.toDoBadge
              : styles.partialBadge
          }
        />
      )}
    </div>
  </div>
);

export default RepositioningCard;
