import {
  Button,
  Checkbox,
  LetterBadge,
  ModalDeprecated,
  PopupActions,
  TextArea,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useRef, useState, useEffect } from 'react';
import styles from './MemoModal.module.scss';
import { Props } from './MemoModal.types';
import MemoCard from 'src/Components/MemoCard/MemoCard.component';
import { LoadingCard } from 'src/Components/Card';
import { FETCH_STATUS } from 'src/Redux/Types';
import classnames from 'classnames';
import moment from 'moment';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import { useSelector } from 'react-redux';
import { getAnalyticsUserInfo } from 'src/Redux/Perimeter/Selectors';
import {
  CandidateMemo,
  ConsultantInfo,
  OSMConsultantModelDtoFullConsultantDto,
} from 'src/Services/API';
import { useCreateCandidateMemo } from 'src/Hooks/Candidate/useCreateCandidateMemo';
import { useDeleteCandidateMemo } from 'src/Hooks/Candidate/useDeleteCandidateMemo';
import { useEditCandidateMemo } from 'src/Hooks/Candidate/useEditCandidateMemo';
import { Memo } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';

const MemoModal = ({ memos, fetchStatus, candidateId, isCDI }: Props) => {
  const ref = useRef<PopupActions>(null);
  const userInfo = useSelector(getAnalyticsUserInfo);
  const [localMemos, setLocalMemos] = useState<CandidateMemo[]>(memos || []);
  const user = useAuthenticatedUser();

  useEffect(() => {
    setLocalMemos(memos || []);
  }, [memos]);

  const [openMemoCreationModal, setOpenMemoCreationModal] = useState<boolean>(false);
  const [openMemoEditModal, setOpenMemoEditModal] = useState<boolean>(false);
  const [currentMemoId, setCurrentMemoId] = useState<string | null>(null);
  const [comment, setComment] = useState('');
  const [isBehaviorMemo, setIsBehaviorMemo] = useState(false);
  const createMemo = useCreateCandidateMemo();
  const deleteMemo = useDeleteCandidateMemo();
  const editMemo = useEditCandidateMemo();

  const oneBehaviorMemo = localMemos.some(memo => memo.isBehaviorMemo === true);

  const handleAddMemo = async () => {
    const tempId = Math.random().toString(36).substr(2, 9);
    const newMemo = new CandidateMemo({
      id: tempId,
      consultantId: 'temporaryId',
      date: new Date(),
      comment,
      isBehaviorMemo,
      consultant: new ConsultantInfo({
        consultant: new OSMConsultantModelDtoFullConsultantDto({
          name: user.familyName,
          firstName: user.givenName,
        }),
      }),
    });

    setLocalMemos([newMemo, ...localMemos]);

    try {
      const actualId = await createMemo.mutateAsync({
        candidateId: candidateId,
        comment: comment,
        isBehaviorMemo: isBehaviorMemo,
        consultant: new OSMConsultantModelDtoFullConsultantDto({
          name: user.familyName,
          firstName: user.givenName,
        }),
      });
      setLocalMemos(prevMemos =>
        prevMemos.map(memo => (memo.id === tempId ? { ...memo, id: actualId } : memo))
      );
    } catch (error) {
      setLocalMemos(prevMemos => prevMemos.filter(memo => memo.id !== tempId));
    } finally {
      setComment('');
      setIsBehaviorMemo(false);
      setOpenMemoCreationModal(false);
      ReactGA.event(ANALYTICS_EVENT.MEMO_CREATION, {
        ...userInfo,
      });
      if (isBehaviorMemo) {
        ReactGA.event(ANALYTICS_EVENT.DISCIPLINARY_MEMO_CREATION, {
          ...userInfo,
        });
      }
    }
  };

  const handleEditMemo = async () => {
    if (!currentMemoId) return;

    const tempId = Math.random().toString(36).substr(2, 9);
    const editedMemo = new CandidateMemo({
      id: tempId,
      consultantId: 'temporaryId',
      date: new Date(),
      comment,
      isBehaviorMemo,
      consultant: new ConsultantInfo({
        consultant: new OSMConsultantModelDtoFullConsultantDto({
          name: user.familyName,
          firstName: user.givenName,
        }),
      }),
    });

    setLocalMemos(prevMemos => [
      editedMemo,
      ...prevMemos.filter(memo => memo.id !== currentMemoId),
    ]);

    try {
      const actualId = await editMemo.mutateAsync({
        memoId: currentMemoId,
        candidateId: candidateId,
        comment: comment,
        isBehaviorMemo: isBehaviorMemo,
        brandCode: '',
      });
      setLocalMemos(prevMemos =>
        prevMemos.map(memo => (memo.id === tempId ? { ...memo, id: actualId } : memo))
      );
    } catch (error) {
      setLocalMemos(prevMemos => {
        const restoredMemo = prevMemos.find(memo => memo.id === tempId);
        return [
          ...(restoredMemo ? [restoredMemo] : []),
          ...prevMemos.filter(memo => memo.id !== tempId),
        ];
      });
    } finally {
      setComment('');
      setIsBehaviorMemo(false);
      setOpenMemoEditModal(false);
      setCurrentMemoId(null);
    }
  };

  const handleDeleteMemo = async (memoId: string) => {
    const originalMemos = [...localMemos];
    setLocalMemos(localMemos.filter(memo => memo.id !== memoId));

    try {
      await deleteMemo.mutateAsync({
        candidateId: candidateId,
        memoId: memoId,
      });
    } catch (error) {
      setLocalMemos(originalMemos);
    }
  };

  const openEditModal = (memo: CandidateMemo) => {
    setComment(memo.comment || '');
    setIsBehaviorMemo(memo.isBehaviorMemo || false);
    setCurrentMemoId(memo.id || '');
    setOpenMemoEditModal(true);
  };

  return (
    <ModalDeprecated
      ref={ref}
      title="liste des mémos"
      footerActionsLeft={[
        <Button.Secondary
          key="memoModalCreationButton"
          className={styles.addButton}
          onClick={() => setOpenMemoCreationModal(true)}
        >
          ajouter
        </Button.Secondary>,
      ]}
      footerActionsRight={[
        <Button.Primary
          key="memoModalCancelButton"
          className={styles.closeButton}
          onClick={() => ref.current?.close()}
        >
          fermer
        </Button.Primary>,
      ]}
      trigger={
        isCDI ? (
          <div className={styles.cdiButton}>
            mémos
            <LetterBadge
              text={(localMemos?.length ?? 0).toString()}
              className={classnames(styles.memoBadge, {
                [styles.memoBadgeBehavior]: oneBehaviorMemo,
              })}
            />
          </div>
        ) : (
          <Button.Secondary className={styles.memoButton}>
            mémos
            <LetterBadge
              text={(localMemos?.length ?? 0).toString()}
              className={classnames(styles.memoBadge, {
                [styles.memoBadgeBehavior]: oneBehaviorMemo,
              })}
            />
          </Button.Secondary>
        )
      }
      icon={<Memo />}
      iconClassName={styles.icon}
    >
      <div className={styles.subtitle}>{`${localMemos?.length} mémos`}</div>

      {fetchStatus !== FETCH_STATUS.FULFILLED ? (
        <div className={styles.loadingContainer}>
          {Array.from({ length: 2 }).map((_, idx) => (
            <div className={styles.loadingCard} key={`${idx} memoCard`}>
              <LoadingCard />
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.listMemos}>
          {localMemos?.map((memo, idx) => {
            const consultant = memo.consultant?.consultant;
            return (
              <MemoCard
                key={memo.id}
                isLastMemo={idx === 0}
                id={memo.id ?? ''}
                candidateId={candidateId}
                title={
                  memo.date
                    ? `${moment(memo.date).subtract(1, 'month').format('L')}`
                    : 'date inconnue'
                }
                subtitle={
                  consultant?.firstName && consultant?.name
                    ? `${consultant?.firstName} ${consultant?.name}`
                    : 'consultant inconnu'
                }
                description={memo.comment ?? ''}
                isBehaviorMemo={memo.isBehaviorMemo ?? false}
                onDelete={handleDeleteMemo}
                onEdit={() => openEditModal(memo)}
              />
            );
          })}
        </div>
      )}

      {openMemoCreationModal && (
        <ModalDeprecated
          onClose={() => {
            setOpenMemoCreationModal(false);
            setComment('');
            setIsBehaviorMemo(false);
          }}
          ref={ref}
          title="Ajouter un mémo"
          open={openMemoCreationModal}
          subtitle={
            <a
              href="https://drive.google.com/file/d/1SDuEoSZdM5I50EIUm1vGjfXIHqBYJMBr/view"
              target="_blank"
              rel="noreferrer"
              className={styles.memoGuideRedirect}
            >
              Ouvrir le guide de saisie des mémos
            </a>
          }
          footerActionsRight={[
            <Button.Secondary
              key="memoModalCancelButton"
              className={styles.closeButton}
              onClick={() => {
                setOpenMemoCreationModal(false);
                setComment('');
                setIsBehaviorMemo(false);
              }}
            >
              annuler
            </Button.Secondary>,
            <Button.Primary
              key="memoModalAddButton"
              className={styles.addButton}
              disabled={comment.length < 1}
              onClick={handleAddMemo}
            >
              envoyer
            </Button.Primary>,
          ]}
        >
          <WithLightTitle title="commentaire (1 caractère min)" className={styles.textAreaTitle}>
            <TextArea
              placeholder="aucun commentaire renseigné"
              className={styles.textArea}
              value={comment}
              minLength={1}
              maxLength={4000}
              onChange={event => setComment((event.target as HTMLTextAreaElement).value)}
            />
          </WithLightTitle>
          <Checkbox
            label="le mémo est à caractère disciplinaire"
            checked={isBehaviorMemo}
            onClick={() => {
              setIsBehaviorMemo(!isBehaviorMemo);
            }}
          />
        </ModalDeprecated>
      )}

      {openMemoEditModal && (
        <ModalDeprecated
          onClose={() => {
            setOpenMemoEditModal(false);
            setComment('');
            setIsBehaviorMemo(false);
            setCurrentMemoId(null);
          }}
          ref={ref}
          title="Modifier un mémo"
          open={openMemoEditModal}
          subtitle={
            <a
              href="https://drive.google.com/file/d/1SDuEoSZdM5I50EIUm1vGjfXIHqBYJMBr/view"
              target="_blank"
              rel="noreferrer"
              className={styles.memoGuideRedirect}
            >
              Ouvrir le guide de saisie des mémos
            </a>
          }
          footerActionsRight={[
            <Button.Secondary
              key="memoModalCancelButton"
              className={styles.closeButton}
              onClick={() => {
                setOpenMemoEditModal(false);
                setComment('');
                setIsBehaviorMemo(false);
                setCurrentMemoId(null);
              }}
            >
              annuler
            </Button.Secondary>,
            <Button.Primary
              key="memoModalEditButton"
              className={styles.addButton}
              disabled={comment.length < 1}
              onClick={handleEditMemo}
            >
              envoyer
            </Button.Primary>,
          ]}
        >
          <WithLightTitle title="commentaire (1 caractère min)" className={styles.textAreaTitle}>
            <TextArea
              placeholder="aucun commentaire renseigné"
              className={styles.textArea}
              value={comment}
              minLength={1}
              maxLength={4000}
              onChange={event => setComment((event.target as HTMLTextAreaElement).value)}
            />
          </WithLightTitle>
          <Checkbox
            label="le mémo est à caractère disciplinaire"
            checked={isBehaviorMemo}
            onClick={() => {
              setIsBehaviorMemo(!isBehaviorMemo);
            }}
          />
        </ModalDeprecated>
      )}
    </ModalDeprecated>
  );
};

export default MemoModal;
