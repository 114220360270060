import moment from 'moment';
import { useRef } from 'react';

import { Calendar, CalendarFocus } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, DatePicker, Popover } from '@randstad-lean-mobile-factory/react-components-core';
import { DatePickerActions } from '@randstad-lean-mobile-factory/react-components-core/dist/Calendars/DatePicker/DatePicker.types';
import { PivotDatePickerProps } from './PivotDatePicker.types';

import styles from './PivotDatePicker.module.scss';

export const PivotDatePicker = ({ pivotDate, setPivotDate }: PivotDatePickerProps) => {
  const ref = useRef<DatePickerActions>(null);

  return (
    <Popover
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      trigger={
        <Button.Secondary.XSmall
          leftIcon={<Calendar variant="fill" />}
          text={`à partir du ${moment(pivotDate).format('DD/MM/YYYY')}`}
        />
      }
      slotProps={{ paper: { className: styles.popover } }}
    >
      <DatePicker selected={pivotDate} onChange={setPivotDate} showYearDropdown ref={ref} />
      <div className={styles.footer}>
        <Button.Tertiary.XSmall onClick={() => setPivotDate(new Date())} text="réinitialiser" />
        <Button.Tertiary.XSmall
          onClick={() => ref.current?.goToToday()}
          rightIcon={<CalendarFocus />}
          text="aujourd'hui"
        />
      </div>
    </Popover>
  );
};
