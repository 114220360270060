import { AnyAction, createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkAPI } from 'src/Redux/RootReducer';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { getSortMode } from 'src/Redux/Search/Selectors';
import moment from 'moment';
import {
  MissionDetail,
  MissionsService,
  PositionstudiesService,
  YouplanMissionsService,
} from 'src/Services/API';
import {
  getCandidateId,
  getNumberPositions,
  getProcessedMission,
  getSelectedCompany,
  getSelectedQualification,
  getYouplanMissionCandidates,
  getYouplanMissionIds,
} from './Selectors';
import MissionCreationState from './Types';
import { fetchServices } from '../Services/Thunks';
import { getCompanyRequestReasons } from '../Companies/Thunks';
import ReactGA from 'react-ga4';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';

export const searchPreviousMissions = createAsyncThunk<
  MissionCreationState['previousMissions'],
  undefined,
  ThunkAPI
>('previousMissions/search', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const selectedQualification = getSelectedQualification(state);
  const selectedCompany = getSelectedCompany(state);
  const brandCode = getCurrentBrandCode(state) || '';
  const agencyId = getCurrentAgency(state) || '';
  const startDate = moment().subtract(3, 'months').toISOString();
  const endDate = moment().add(15, 'months').toISOString();
  const sortBy = getSortMode(state);
  const previousMissionsWithCount = await MissionsService.missionsControllerGetMissions({
    body: {
      startDate,
      endDate,
      brandCode,
      agencyIds: [agencyId],
      qualificationIds: selectedQualification?.id ? [selectedQualification.id] : undefined,
      companyId: selectedCompany?.companyId,
      offset: 0,
      sortBy,
    },
  });
  return (
    previousMissionsWithCount.missions
      ?.sort(
        (a, b) =>
          new Date(b?.modificationDate ?? 0).getTime() -
          new Date(a?.modificationDate ?? 0).getTime()
      )
      .slice(0, 5) ?? []
  );
});

export const searchPreviousPositionStudies = createAsyncThunk<
  MissionCreationState['previousPositionStudies'],
  undefined,
  ThunkAPI
>('previousPositionStudies/search', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const selectedQualification = getSelectedQualification(state);
  const selectedCompany = getSelectedCompany(state);
  const brandCode = getCurrentBrandCode(state) || '';
  const agencyId = getCurrentAgency(state) || '';
  const since = moment().subtract(2, 'year').toISOString();
  const previousPositionStudies = await PositionstudiesService.positionStudiesControllerGetPositionStudies(
    {
      qualificationId: selectedQualification?.id ?? '',
      companyId: selectedCompany?.companyId ?? '',
      offset: 0,
      limit: 5,
      brandCode,
      agencyId,
      since,
    }
  );
  return previousPositionStudies;
});

export const createMissionWithoutSchedule = createAsyncThunk<
  string | undefined,
  Array<ANALYTICS_EVENT>,
  ThunkAPI
>('createMissionWithoutSchedule', async (analyticsEvents, thunkAPI) => {
  const state = thunkAPI.getState();
  const brandCode = getCurrentBrandCode(state) || '';
  const mission = getProcessedMission(state);
  const qualification = getSelectedQualification(state);
  const company = getSelectedCompany(state);
  const numberPositions = getNumberPositions(state);
  const candidates = getYouplanMissionCandidates(state);
  const candidateId = getCandidateId(state);
  const qualificationId = qualification?.id ?? '';
  const companyId = company?.companyId;
  const agencyId = getCurrentAgency(state) || '';
  const startDate = mission.startDate ? new Date(mission.startDate).toISOString() : '';
  const endDate = mission.endDate ? new Date(mission.endDate).toISOString() : '';
  const reasonId: number | undefined = mission.reasonId ? +mission.reasonId : undefined;
  const serviceId = mission.service?.id;
  const structuredMissionHours = mission.structuredMissionHours.filter(
    structuredMissionHour => structuredMissionHour.days.length !== 0
  );
  const userInfo = getAnalyticsUserInfo(state);
  const newMissionId = await MissionsService.missionsControllerCreateMissionWithoutSchedule({
    body: {
      ...mission,
      qualificationId,
      numberPositions,
      startDate,
      endDate,
      companyId,
      reasonId,
      agencyId,
      serviceId,
      structuredMissionHours,
      workLocation: mission.workLocation,
      candidates: candidateId !== undefined ? [{ candidateId: candidateId }] : candidates,
    },
    brandCode,
  });
  analyticsEvents.forEach(analyticsEvent => {
    ReactGA.event(analyticsEvent, {
      ...userInfo,
      startDate,
      endDate,
      workLocation: mission.workLocation,
      service: mission.service,
    });
  });
  if (candidates.length > 0) {
    ReactGA.event(ANALYTICS_EVENT.COMMAND_RATTACHEMENT_FROM_MY_REGION_DURING_CREATION, {
      ...userInfo,
      rattachementCount: candidates.length,
    });
  }
  return newMissionId.missionId;
});

export const createMissionWithoutScheduleFromYouplan = createAsyncThunk<
  string | undefined,
  undefined,
  ThunkAPI
>('createMissionWithoutScheduleFromYouplan', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const brandCode = getCurrentBrandCode(state) || '';
  const mission = getProcessedMission(state);
  const qualification = getSelectedQualification(state);
  const numberPositions = getNumberPositions(state);
  const company = getSelectedCompany(state);
  const qualificationId = qualification?.id ?? '';
  const companyId = company?.companyId;
  const agencyId = getCurrentAgency(state) || '';
  const startDate = mission.startDate ? new Date(mission.startDate).toISOString() : '';
  const candidates = getYouplanMissionCandidates(state);
  const endDate = mission.endDate ? new Date(mission.endDate).toISOString() : '';
  const reasonId: number | undefined = mission.reasonId ? +mission.reasonId : undefined;
  const serviceId = mission.service?.id;
  const structuredMissionHours = mission.structuredMissionHours.filter(
    structuredMissionHour => structuredMissionHour.days.length !== 0
  );
  const userInfo = getAnalyticsUserInfo(state);

  const youplanMissionIds = getYouplanMissionIds(state);
  const newMissionId = await YouplanMissionsService.youplanMissionsControllerCreateMissionFromYouplan(
    {
      body: {
        mission: {
          ...mission,
          numberPositions,
          candidates,
          qualificationId,
          startDate,
          endDate,
          companyId,
          reasonId,
          agencyId,
          serviceId,
          structuredMissionHours,
        },
        youplanMissionIds,
      },
      brandCode,
    }
  );
  ReactGA.event(ANALYTICS_EVENT.YOUPLAN_COMMAND_CREATION, {
    ...userInfo,
    startDate,
    endDate,
    workLocation: mission.workLocation,
    service: mission.service,
  });

  return newMissionId.missionId;
});

export const fetchPreviousMissionDetail = createAsyncThunk<MissionDetail, string, ThunkAPI>(
  'previousMissions/fetchById/forNewMission',
  async (id: string, thunkAPI) => {
    const state = thunkAPI.getState();
    const brandCode = getCurrentBrandCode(state) || '';
    return MissionsService.missionsControllerGetMission({ id, brandCode });
  }
);

export const fetchYouplanMissionForNewMission = createAsyncThunk<MissionDetail, string, ThunkAPI>(
  'youplanMissions/fetchById/forNewMission',
  async (id: string, thunkAPI) => {
    const state = thunkAPI.getState();
    const brandCode = getCurrentBrandCode(state) || '';
    const result = await YouplanMissionsService.youplanMissionsControllerGetYouplanMissionDetail({
      id,
    });
    thunkAPI.dispatch(
      (fetchServices({
        brandCode: brandCode ?? ' ',
        id: result.company?.companyId ?? ' ',
      }) as unknown) as AnyAction
    );
    thunkAPI.dispatch(
      (getCompanyRequestReasons({
        brandCode: brandCode ?? ' ',
        id: result.company?.companyId ?? ' ',
      }) as unknown) as AnyAction
    );
    return result;
  }
);

export const getPositionStudiesCount = createAsyncThunk<number, undefined, ThunkAPI>(
  'positionStudies/count',
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const selectedQualification = getSelectedQualification(state);
    const selectedCompany = getSelectedCompany(state);
    const brandCode = getCurrentBrandCode(state) || '';
    const agencyId = getCurrentAgency(state) || '';
    const since = moment().subtract(2, 'year').toISOString();
    const count = await PositionstudiesService.positionStudiesControllerGetPositionStudiesCount({
      qualificationId: selectedQualification?.id ?? '',
      companyId: selectedCompany?.companyId ?? '',
      agencyId,
      brandCode,
      since,
    });
    return count;
  }
);
