import moment from 'moment';
import { TALENTS_STATUS } from './TemporaryWorkers.types';

export const getTalentStatus = (
  lastContractEndDate?: Date,
  availabilityDate?: Date
): TALENTS_STATUS => {
  const normalizeDate = (date?: Date) =>
    moment(date).hours(12).minutes(0).seconds(0).milliseconds(0);

  const now = normalizeDate();
  const availableDate = normalizeDate(availabilityDate);
  if (now.isAfter(availableDate)) {
    return TALENTS_STATUS.INTERMISSION;
  }

  if (now.isAfter(availableDate.subtract(2, 'days'))) {
    return TALENTS_STATUS.SOON_AVAILABLE;
  }

  const contractEndDate = lastContractEndDate ? normalizeDate(lastContractEndDate) : moment(0);
  if (now.isAfter(contractEndDate)) {
    return TALENTS_STATUS.ABSENT;
  }

  return TALENTS_STATUS.IN_MISSION;
};
