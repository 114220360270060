import { createSelector } from '@reduxjs/toolkit';
import { TalentFilterState } from './Types';
import { RootState } from '../RootReducer';

const getTalentFilterState = (state: RootState) => state.talentFilter;

export const getCurrentQualifications = createSelector(
  [getTalentFilterState],
  (state: TalentFilterState) => state.currentQualifications
);

export const getCurrentName = createSelector(
  [getTalentFilterState],
  (state: TalentFilterState) => state.currentName
);

export const getCurrentStatuses = createSelector(
  [getTalentFilterState],
  (state: TalentFilterState) => state.currentStatuses
);

export const getCurrentInterval = createSelector(
  [getTalentFilterState],
  (state: TalentFilterState) => state.currentInterval
);
