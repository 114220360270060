import { useState } from 'react';

import { Button, PopupMenu } from '@randstad-lean-mobile-factory/react-components-core';
import {
  AscendingOrder,
  DescendingOrder,
  Sort,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { getCDISortBy, getCDISortOrder } from 'src/Redux/CDICandidates/Selectors';
import { CDISortBy, SortOrder } from 'src/Redux/CDICandidates/Types';
import { getRepositioningMissionsSortBy } from 'src/Redux/Missions/Selectors';
import { RepositioningMissionsSortBy } from 'src/Redux/Missions/Types';
import styles from './FiltersMenu.module.scss';
import FiltersMenuContent from './FiltersMenuContent/FiltersMenuContent.component';

export const sortingChoicesCDII = [
  {
    mode: CDISortBy.AGENCY,
    name: 'agence',
  },
  {
    mode: CDISortBy.CONTRACT_END_DATE,
    name: 'date',
  },
  {
    mode: CDISortBy.STATUT,
    name: 'statut',
  },
  {
    mode: CDISortBy.NEXT_AVAILABILITY,
    name: 'prochaine dispo',
  },
];

export const sortingChoicesMission = [
  {
    mode: RepositioningMissionsSortBy.DISTANCE,
    name: 'distance',
  },
  {
    mode: RepositioningMissionsSortBy.NB_POSITIONS,
    name: 'nombre de postes',
  },
  {
    mode: RepositioningMissionsSortBy.START_DATE,
    name: 'date',
  },
  {
    mode: RepositioningMissionsSortBy.STATUT,
    name: 'statut',
  },
];

export const sortOrderChoices = [
  {
    mode: SortOrder.ASC,
    name: 'croissant',
    icon: <AscendingOrder className={styles.menuIcon} />,
  },
  {
    mode: SortOrder.DESC,
    name: 'décroissant',
    icon: <DescendingOrder className={styles.menuIcon} />,
  },
];

const FiltersMenu = ({ isRepositioning }: { isRepositioning: boolean }) => {
  const sortByCDII = useSelector(getCDISortBy);
  const sortByMission = useSelector(getRepositioningMissionsSortBy);
  const sortOrderCDI = useSelector(getCDISortOrder);

  const [localSortBy, setLocalSortBy] = useState(isRepositioning ? sortByMission : sortByCDII);
  const [localSortOrder, setLocalSortOrder] = useState(sortOrderCDI);

  const [isOpened, setIsOpened] = useState(false);
  return (
    <PopupMenu
      position="bottom left"
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      contentStyle={{ width: '15.25rem' }}
      trigger={
        <div>
          <Button.Tertiary className={classnames(styles.button, { [styles.openButton]: isOpened })}>
            <Sort />
          </Button.Tertiary>
        </div>
      }
      selectedIds={[
        isRepositioning
          ? sortingChoicesMission.find(choice => choice.mode === localSortBy)?.name ?? ''
          : sortingChoicesCDII.find(choice => choice.mode === localSortBy)?.name ?? '',
        sortOrderChoices.find(dpm => dpm.mode === localSortOrder)?.name ?? '',
      ]}
    >
      <FiltersMenuContent
        localSortBy={localSortBy}
        localSortOrder={localSortOrder}
        setLocalSortOrder={setLocalSortOrder}
        setLocalSortBy={setLocalSortBy}
        isRepositioning={isRepositioning}
      />
    </PopupMenu>
  );
};

export default FiltersMenu;
