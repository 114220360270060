import { AppelMedicalEventSource, ExternalDemandDto, ExternalDemandItem } from 'src/Services/API';
import { ColumnFiltersState, PaginationState, SortingState } from '@tanstack/react-table';

export interface Props {
  data: ExternalDemandDto[];
  count: number;
  isFetching: boolean;
  searchInput: string;
  source: AppelMedicalEventSource;
  filters: ColumnFiltersState;
  setFilters: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
  sorting: SortingState;
  setSorting: React.Dispatch<React.SetStateAction<SortingState>>;
}

export interface ExternalDemandChild extends ExternalDemandItem {
  parent: ExternalDemandDto;
}

export type ExternalDemandElement = ExternalDemandDto | ExternalDemandChild;

export const isDemandItem = (element: ExternalDemandElement): element is ExternalDemandChild =>
  !(element as ExternalDemandDto).items;

export const isDemandDto = (element: ExternalDemandElement): element is ExternalDemandDto =>
  Boolean((element as ExternalDemandDto).items);

export const isDemandDtoWithChildren = (
  element: ExternalDemandElement
): element is ExternalDemandDto =>
  Boolean((element as ExternalDemandDto).items) && (element as ExternalDemandDto).items.length > 1;

export const filterUniqueIds = (objects: ExternalDemandItem[]) => {
  const seenIds = new Set();
  return objects.filter(obj => {
    if (!seenIds.has(obj.id)) {
      seenIds.add(obj.id);
      return true;
    }
    return false;
  });
};
