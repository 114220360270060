import {
  BadgeDeprecated,
  BadgeDropdown,
  Button,
} from '@randstad-lean-mobile-factory/react-components-core';
import { WrongCircle } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { startOfWeek } from 'date-fns';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFetchRelatedCGC } from 'src/Hooks/Agencies/useFetchRelatedCGC';
import { useChangeManuallyCandidateRepoStatus } from 'src/Hooks/Candidate/useChangeManuallyCandidateRepoStatus';
import { useFetchCandidateActivityPlanning } from 'src/Hooks/Candidate/useFetchCandidateActivityPlanning';
import { useFetchCandidateDetails } from 'src/Hooks/Candidate/useFetchCandidateDetails';
import { useIsCGC } from 'src/Hooks/SkillsManagementCenters/useIsCGC';
import { QueryKeys } from 'src/Hooks/types';
import { MODAL_ROUTES } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import { getAnalyticsUserInfo, getCurrentAgency } from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { dashboardMenuLabel } from 'src/Routes/Talents/Cdii/Cdii.component';
import { CDII_ACTIONS, CDII_STATUS } from 'src/Routes/Talents/Cdii/Cdii.types';
import { getEndMission, getRepositionedStatus } from 'src/Routes/Talents/Cdii/utils';
import { DASHBOARD_MENU } from 'src/Routes/Talents/Repositioning';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';
import styles from './CdiiDetail.module.scss';
import { CdiiDetailProps } from './CdiiDetail.types';
import { CdiiDetailSection } from './CdiiDetailSection';

export const CdiiDetailScreen = ({
  showCloseButton,
  onClose,
  statut,
  candidateId,
  dashboardLabel,
}: CdiiDetailProps) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: isCGC } = useIsCGC();
  const userInfo = useSelector(getAnalyticsUserInfo);
  const [planningStartDate, setPlanningStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const [fetchingStartDate, setFetchingStartDate] = useState(
    startOfWeek(new Date(), { weekStartsOn: 1 })
  );
  const relatedCGCQuery = useFetchRelatedCGC();
  const currentAgency = useSelector(getCurrentAgency);
  const changeManuallyCandidateRepoStatusMutation = useChangeManuallyCandidateRepoStatus();

  const { data: candidate, isSuccess, isLoading } = useFetchCandidateActivityPlanning({
    candidateId: candidateId ?? '',
    startDate: fetchingStartDate,
  });
  const { data: detailedCandidate, isSuccess: detailedSuccess } = useFetchCandidateDetails(
    candidateId ?? ''
  );
  const repositionedCdiiStatus = isSuccess
    ? getRepositionedStatus(candidate.cdiiStatus)
    : undefined;
  const listStatus = [
    { key: CDII_STATUS.AVAILABLE, className: styles.badgeAvailable },
    { key: CDII_STATUS.SUGGESTED, className: styles.badgeSuggested },
    { key: CDII_STATUS.PROCESSING, className: styles.badgeProcessing },
    { key: CDII_ACTIONS.EMPTY_STATUS, className: styles.badgeEmpty },
    { key: CDII_ACTIONS.DENY, className: styles.badgeEmpty },
  ];

  const [repoStatus, setRepoStatus] = useState(
    listStatus.find(item => item.key === repositionedCdiiStatus)
  );

  const suggestedMissionsIds = candidate?.suggestedMissionIds ?? [];
  const cdiiStatus = useMemo(
    () =>
      getEndMission(
        candidate?.lastContract?.endDate,
        candidate?.activityPlanning?.find(
          day => moment(day.date)?.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')
        )?.status
      ),
    [candidate]
  );

  useEffect(() => {
    if (candidate && repositionedCdiiStatus) {
      setRepoStatus(listStatus.find(item => item.key === repositionedCdiiStatus));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [repositionedCdiiStatus]);

  useEffect(() => {
    if (
      moment(planningStartDate).isBefore(moment(fetchingStartDate)) ||
      moment(planningStartDate).isAfter(
        moment(fetchingStartDate).add(3, 'weeks').subtract(1, 'day')
      )
    ) {
      setFetchingStartDate(planningStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningStartDate]);

  useEffect(() => {
    if (candidate && moment(planningStartDate).isAfter(moment(fetchingStartDate))) {
      queryClient.invalidateQueries({ queryKey: [QueryKeys.fetchCandidateActivityPlanning] });
      setFetchingStartDate(planningStartDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planningStartDate]);

  const navigateToMissions = useCallback(() => {
    navigate(ROUTES.TALENTS.CDII.CDII_DETAIL.REPOSITIONING.buildPath({ candidateId }));
  }, [candidateId, navigate]);

  return (
    <div className={styles.container}>
      <div className={styles.sliderContent}>
        {showCloseButton && <WrongCircle onClick={onClose} className={styles.closeButton} />}
        <div className={styles.nameAndBadge}>
          {isSuccess && detailedSuccess && (
            <>
              <div className={styles.title}>{`${candidate?.firstName} ${candidate?.name} `}</div>
              <div className={styles.unityAndBadge}>
                <div className={styles.unity}>
                  {(candidate?.lastContract?.agency?.id ?? 'non précisé') + ' | '}
                </div>
                <BadgeDeprecated
                  value={cdiiStatus}
                  className={classnames({
                    [styles.badgeInMission]: cdiiStatus === CDII_STATUS.MISSION,
                    [styles.badgeSoonAvailable]: cdiiStatus === CDII_STATUS.SOON_AVAILABLE,
                    [styles.badgeIntermission]: cdiiStatus === CDII_STATUS.INTERMISSION,
                    [styles.badgeAbsent]: cdiiStatus === CDII_STATUS.ABSENT,
                  })}
                />
                {repoStatus !== undefined &&
                  dashboardLabel &&
                  [DASHBOARD_MENU.REPOSITIONED, DASHBOARD_MENU.REGIONAL].includes(
                    dashboardLabel
                  ) && (
                    <BadgeDropdown
                      items={listStatus
                        .filter(status => {
                          if (
                            status.key !== CDII_STATUS.AVAILABLE ||
                            status.key === repoStatus.key
                          ) {
                            switch (repoStatus.key) {
                              case CDII_STATUS.AVAILABLE:
                                return (
                                  status.key !== CDII_STATUS.PROCESSING &&
                                  status.key !== CDII_STATUS.SUGGESTED
                                );
                              default:
                                return true;
                            }
                          }
                          return false;
                        })
                        .filter(status => {
                          if (
                            status.key === CDII_ACTIONS.DENY &&
                            (!candidate.suggestedAgencyIds?.[0] || isCGC)
                          ) {
                            return false;
                          }
                          return !(!isCGC && status.key !== CDII_ACTIONS.DENY);
                        })}
                      width={180}
                      keyValueExtractor={(searchresult: {
                        key: CDII_STATUS | string;
                        className: string;
                      }) => {
                        return {
                          key: searchresult.key,
                          value: searchresult.key,
                          className: searchresult.className,
                        };
                      }}
                      selectedItem={repoStatus}
                      onSelectItem={(searchresult: {
                        key: CDII_STATUS | string;
                        className: string;
                      }) => {
                        searchresult.key === CDII_STATUS.PROCESSING &&
                        repoStatus.key !== CDII_STATUS.PROCESSING
                          ? navigate(MODAL_ROUTES.ATTACH_SUGGESTED_CANDIDATE.route.buildPath({}), {
                              state: {
                                background: location,
                                modalParameters: {
                                  missionIds: candidate?.suggestedMissionIds,
                                  candidate: candidate,
                                },
                              },
                            })
                          : searchresult.key === CDII_ACTIONS.DENY
                          ? navigate(MODAL_ROUTES.REFUSE_SUGGESTED_CANDIDATE.route.buildPath({}), {
                              state: {
                                background: location,
                                modalParameters: {
                                  ...candidate,
                                  ...detailedCandidate,
                                },
                              },
                            })
                          : searchresult.key !== repoStatus.key &&
                            changeManuallyCandidateRepoStatusMutation.mutate({
                              candidateId: candidate?.id ?? '',
                              firstName: candidate?.firstName ?? '',
                              lastName: candidate?.name,
                              cgcId: isCGC ? currentAgency ?? '' : relatedCGCQuery.data?.id ?? '',
                              comment: '',
                              agencyId: currentAgency ?? '',
                              suggestedMissionsIds: suggestedMissionsIds,
                              refuseAll: true,
                              oldStatus: repoStatus.key,
                              newStatus: searchresult.key,
                            });
                      }}
                    />
                  )}
              </div>
            </>
          )}
          {isLoading && (
            <ContentLoader height="2rem" width="100%" uniqueKey="calendarBadge">
              <rect x="1%" y="10" rx="4" ry="4" width="50%" height="10" />
            </ContentLoader>
          )}
        </div>

        <Button.Primary
          className={styles.repositioningButton}
          onClick={() => {
            if (isCGC) {
              ReactGA.event(ANALYTICS_EVENT.CLICK_SUGGEST, {
                ...userInfo,
                statut,
              });
            } else {
              ReactGA.event(ANALYTICS_EVENT.CLICK_REPOSITIONING, {
                ...userInfo,
                statut,
                toggle: dashboardLabel ? dashboardMenuLabel[dashboardLabel] : 'deeplink',
              });
            }

            navigateToMissions();
          }}
        >
          {isCGC ? 'suggérer' : 'repositionner'}
        </Button.Primary>
      </div>

      <div className={styles.separator} />
      <CdiiDetailSection
        candidateId={candidateId ?? ''}
        lastContractEndDate={candidate?.lastContract?.endDate}
        fetchingStartDate={fetchingStartDate}
        planningStartDate={planningStartDate}
        setPlanningStartDate={setPlanningStartDate}
        activityPlanning={candidate}
      />
    </div>
  );
};
