import {
  BadgeDeprecated,
  Loader,
  SegmentedControl,
} from '@randstad-lean-mobile-factory/react-components-core';
import { RedBullet } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MailParametersList from 'src/Routes/ExternalDemands/DemandsPreferences/MailParametersList';
import { TopBar } from 'src/Components/TopBar';
import { useFetchMailParameters } from 'src/Hooks/MailParameters/useFetchMailParameters';
import { getSelectedSource } from 'src/Redux/ExternalDemandPreferences/Selectors';
import { ExternalDemandPreferencesActions } from 'src/Redux/ExternalDemandPreferences/Slice';
import { AppelMedicalEventSource, MailParameterDto } from 'src/Services/API';
import styles from './DemandsPreferences.module.scss';
import ExternalDemandsPreferencesTopBarComponent from 'src/Components/TopBar/ExternalDemandsPreferencesTopBarComponent/ExternalDemandsPreferencesTopBarComponent.component';

export const DemandsPreferencesRoute = () => {
  const fetchMailParameters = useFetchMailParameters();
  const initialSource = useSelector(getSelectedSource);
  const dispatch = useDispatch();

  const { data: initialParameters, isFetching, isLoading } = fetchMailParameters;
  const [selectedSource, setSelectedSource] = useState<AppelMedicalEventSource>(initialSource);

  const filteredData = useMemo(
    () =>
      Object.values(AppelMedicalEventSource).reduce((acc, source) => {
        acc[source] =
          initialParameters?.filter(mailParameter => mailParameter.source === source) || [];
        return acc;
      }, {} as Record<AppelMedicalEventSource, MailParameterDto[]>),
    [initialParameters]
  );

  return (
    <>
      <div className={styles.container}>
        <TopBar title="table de correspondances">
          <ExternalDemandsPreferencesTopBarComponent />
        </TopBar>
        <div className={styles.options}>
          <SegmentedControl
            className={styles.segmentedControl}
            controls={Object.keys(filteredData) as AppelMedicalEventSource[]}
            selected={selectedSource}
            getValue={(dashboardMenuItem: AppelMedicalEventSource) => {
              const hasMissingParameters =
                filteredData[dashboardMenuItem].filter(
                  mailParameter => mailParameter.osmoseLabel === undefined
                ).length !== 0;
              return (
                <div className={styles.segmentContent}>
                  {dashboardMenuItem.toLowerCase()}
                  <BadgeDeprecated
                    value={`${filteredData[dashboardMenuItem].length}`}
                    className={classnames(
                      styles.badge,
                      dashboardMenuItem === selectedSource && styles.selectedBadge
                    )}
                  />
                  {hasMissingParameters && <RedBullet className={styles.bullet} />}
                </div>
              );
            }}
            onSelectionChange={dashboardMenuItem => {
              setSelectedSource(dashboardMenuItem);
              dispatch(ExternalDemandPreferencesActions.setSelectedSource(dashboardMenuItem));
            }}
          />
        </div>
        {isLoading ? (
          <div className={styles.loadingScreen}>
            <Loader heightInRem={4} className={styles.loader} />
            <div>chargement des correspondances, cela peut prendre du temps</div>
          </div>
        ) : !initialParameters ? (
          <div className={styles.error}>
            Une erreur s'est produite pendant la recherche des correspondances
          </div>
        ) : (
          <MailParametersList
            mailParameters={filteredData[selectedSource]}
            source={selectedSource}
            isFetching={isFetching}
          />
        )}
      </div>
    </>
  );
};
