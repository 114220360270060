import { DataTable } from '@randstad-lean-mobile-factory/react-components-core';
import { RedBullet } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { ColumnDef, SortingState } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import HideableSection from 'src/Components/HideableSection';
import SearchableDropDown from 'src/Components/SearchableDropDown';
import { useFetchCompanyServices } from 'src/Hooks/Companies/useFetchCompanyServices';
import { getCurrentAgency, getCurrentBrandCode } from 'src/Redux/Perimeter/Selectors';
import { MailParameterDto, MailParameterToUpdateDto, TypeField } from 'src/Services/API';
import { DEFAULT_SOURCE_VALUE } from 'src/Utils/constants';

import { useUpdateMailParameters } from 'src/Hooks/ExternalDemands/useUpdateMailParameters';
import { MailParameter } from '../MailParametersList.types';
import styles from './MailParameterTable.module.scss';
import {
  MailParameterTableProps,
  OSMOSE_IGNORE_LABEL,
  OSMOSE_IGNORE_VALUE,
} from './MailParameterTable.types';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';

const MailParametersTable = ({
  group,
  source,
  qualifications,
  domains,
  isOpen,
  onToggle,
  searchInput,
}: MailParameterTableProps) => {
  const queryClient = useQueryClient();
  const brandCode = useSelector(getCurrentBrandCode);
  const agencyId = useSelector(getCurrentAgency) ?? '';

  const { data: servicesData } = useFetchCompanyServices(group.companyId ?? '', brandCode ?? '');
  const services = useMemo(() => servicesData?.map(service => service.name).filter(Boolean) ?? [], [
    servicesData,
  ]);
  const { mutate: updateMailParameter } = useUpdateMailParameters();

  const mailParameters = useMemo(() => {
    return [...group.mailParameters];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domains, qualifications, services, group]);

  const [sortState, setSortState] = useState<SortingState>([{ id: 'isError', desc: true }]);

  const isError = useCallback(
    (mailParameter: MailParameter) => {
      const items = [
        ...(mailParameter.sourceLabel === DEFAULT_SOURCE_VALUE ? [] : [OSMOSE_IGNORE_VALUE]),
        ...(mailParameter.typeField === TypeField.QUALIFICATION
          ? qualifications
          : mailParameter.typeField === TypeField.DOMAINE
          ? domains
          : mailParameter.typeField === TypeField.SERVICE
          ? services
          : []),
      ];

      return (
        (!mailParameter.updatedOsmoseLabel && mailParameter.sourceLabel !== DEFAULT_SOURCE_VALUE) ||
        (!!mailParameter.updatedOsmoseLabel && !items.includes(mailParameter.updatedOsmoseLabel))
      );
    },
    [domains, qualifications, services]
  );

  const columns = useMemo(
    (): ColumnDef<MailParameter>[] => [
      {
        id: 'type',
        header: 'type',
        accessorFn: element => element.typeField,
        filterFn: 'arrIncludes',
      },
      {
        id: 'value',
        header: `valeur ${source.toLowerCase()}`,
        accessorFn: element => element.sourceLabel,
        filterFn: 'arrIncludes',
      },
      {
        id: 'osmose',
        header: 'valeur osmose',
        sortUndefined: 'first',
        accessorFn: element => element.osmoseLabel,
        cell: ({ row }) => {
          const mailParameter = row.original;

          const items = [
            ...(mailParameter.sourceLabel === DEFAULT_SOURCE_VALUE ? [] : [OSMOSE_IGNORE_LABEL]),
            ...(mailParameter.typeField === TypeField.QUALIFICATION
              ? qualifications
              : mailParameter.typeField === TypeField.DOMAINE
              ? domains
              : mailParameter.typeField === TypeField.SERVICE
              ? services
              : []),
          ];

          return (
            <>
              <SearchableDropDown
                data-full-height
                className={styles.dropDown}
                items={
                  mailParameter.updatedOsmoseLabel
                    ? items.includes(mailParameter.updatedOsmoseLabel)
                      ? items
                      : [mailParameter.updatedOsmoseLabel, ...items]
                    : items
                }
                placeholder="sélectionnez"
                canBeReset
                selectedItem={
                  mailParameter.updatedOsmoseLabel === OSMOSE_IGNORE_VALUE
                    ? OSMOSE_IGNORE_LABEL
                    : mailParameter.updatedOsmoseLabel
                }
                onSelectItem={(osmoseLabel?: string) => {
                  if (osmoseLabel === OSMOSE_IGNORE_LABEL) {
                    osmoseLabel = OSMOSE_IGNORE_VALUE;
                  }
                  mailParameter.updatedOsmoseLabel = osmoseLabel;
                  updateMailParameter(
                    {
                      newMailParameter: {
                        ...mailParameter,
                        agencyId: agencyId,
                      } as MailParameterToUpdateDto,
                    },
                    {
                      onSuccess: () => {
                        queryClient.setQueryData(
                          [QueryKeys.fetchMailParameters, agencyId],
                          (oldMailParameters: MailParameterDto[] | undefined) => {
                            if (!oldMailParameters) return oldMailParameters;
                            return oldMailParameters.map(oldMailParameter =>
                              oldMailParameter.id === mailParameter.id
                                ? {
                                    ...oldMailParameter,
                                    osmoseLabel: mailParameter.updatedOsmoseLabel,
                                  }
                                : oldMailParameter
                            );
                          }
                        );
                      },
                      onError: () => {
                        mailParameter.updatedOsmoseLabel = mailParameter.osmoseLabel;
                      },
                    }
                  );
                }}
                error={isError(mailParameter)}
              />
            </>
          );
        },
        filterFn: 'arrIncludes',
      },
      {
        id: 'isError',
        accessorFn: isError,
      },
    ],
    [source, isError, qualifications, domains, services, updateMailParameter, agencyId, queryClient]
  );

  const someErrors = useMemo(
    () =>
      domains.length > 0 &&
      qualifications.length > 0 &&
      services.length > 0 &&
      group.mailParameters.some(isError),
    [domains.length, group.mailParameters, isError, qualifications.length, services.length]
  );

  if (!group.clientName.toLowerCase().trim().includes(searchInput.toLowerCase().trim()))
    return null;

  return (
    <HideableSection
      defaultOpenState={isOpen}
      titleIcon={someErrors && <RedBullet className={styles.bullet} />}
      title={group.clientName}
      dataReady={group.mailParameters.length > 0}
      isOpen={isOpen}
      onToggle={onToggle}
    >
      <DataTable<MailParameter, unknown>
        size="small"
        containerClassName={styles.table}
        stickyHeader
        data={mailParameters}
        columns={columns}
        filterFromLeafRows
        enableMultiSort={false}
        state={{ sorting: sortState, columnVisibility: { isError: false } }}
        onSortingChange={setSortState}
        empty="aucun résultat ne correspond à vos filtres"
      />
    </HideableSection>
  );
};

export default MailParametersTable;
