import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { AgencyCandidatesWithCount, CandidatesService } from 'src/Services/API';
import { QueryKeys } from '../types';

export const useFetchAgencyCandidates = (
  keyword?: string,
  agency?: string,
  brandCode?: string,
  extraOptions?: UseQueryOptions<
    AgencyCandidatesWithCount,
    unknown,
    AgencyCandidatesWithCount,
    (string | undefined)[]
  >
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);
  return useQuery(
    [QueryKeys.fetchAgencyCandidates, keyword, brandCode],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentAgency) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return CandidatesService.candidatesControllerGetAgencyCandidates({
        keyword: keyword,
        brandCode: brandCode ?? currentBrand.brandCodeApiHeader,
        agencyId: agency ?? currentAgency,
      });
    },
    { enabled: keyword !== '', ...extraOptions }
  );
};
