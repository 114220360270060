import { useState } from 'react';

import {
  BadgeDeprecated,
  Button,
  Checkbox,
  PopupMenu,
} from '@randstad-lean-mobile-factory/react-components-core';
import { IdCard } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import QualificationPopupValidateButton from '../QualificationPopupValidateButton';
import styles from './QualificationsPopupMenu.module.scss';
import { Props } from './QualificationsPopupMenu.types';

const QualificationsPopupMenu = ({
  allQualifications,
  selectedQualifications,
  setSelectedQualifications,
}: Props) => {
  const [localQualifications, setLocalQualifications] = useState(selectedQualifications);
  const [open, setOpen] = useState(false);
  let isSelectedAll = localQualifications.length === 0;
  return (
    <PopupMenu
      width={350}
      position="bottom right"
      onClose={() => {
        setSelectedQualifications(localQualifications);
        setOpen(false);
      }}
      onOpen={() => {
        setOpen(true);
        setLocalQualifications(selectedQualifications);
      }}
      trigger={
        <Button.Secondary
          leftIcon={<IdCard />}
          className={classnames(styles.filterButton, { [styles.openPopup]: open })}
          text={
            localQualifications.length > 1 ? 'qualifications du talent' : 'qualification du talent'
          }
          rightIcon={
            <BadgeDeprecated
              value={isSelectedAll ? '1' : localQualifications.length.toString()}
              className={classnames(styles.dot, { [styles.openPopupDot]: open })}
            />
          }
        />
      }
    >
      <div className={styles.popupContainer}>
        <p className={styles.popupMenuTitle}>liste des qualifications du talent</p>
        <div className={styles.popupQualificationsContainer}>
          {allQualifications.map(qualification => {
            const isSelected = localQualifications.includes(qualification.id ?? '');
            return (
              <div className={styles.checkboxContainer} key={qualification.id}>
                <Checkbox
                  key={qualification.id}
                  checked={isSelected}
                  onClick={() =>
                    isSelected
                      ? setLocalQualifications(
                          localQualifications.filter(
                            qualificationItem => qualification.id !== qualificationItem
                          )
                        )
                      : setLocalQualifications([...localQualifications, qualification.id ?? ''])
                  }
                >
                  <p className={styles.checkboxLabel}>{qualification.label?.toLowerCase()}</p>
                </Checkbox>
              </div>
            );
          })}
        </div>
        <p className={styles.popupMenuTitle}>Ouvrir à toutes les qualifications</p>
        <div className={styles.checkboxContainer}>
          <Checkbox
            key="keyAllQualifications"
            checked={isSelectedAll}
            onClick={() =>
              isSelectedAll
                ? setLocalQualifications(
                    allQualifications.map(qualification => qualification.id ?? '')
                  )
                : setLocalQualifications([])
            }
          >
            <p className={styles.checkboxLabel}>Toutes les qualifications</p>
          </Checkbox>
        </div>
        <div className={styles.validateButtonContainer}>
          <QualificationPopupValidateButton />
        </div>
      </div>
    </PopupMenu>
  );
};

export default QualificationsPopupMenu;
