import {
  Action,
  Analysis,
  InterApp,
  License,
  List,
  Megaphone,
  Parameters,
  People,
  Profil,
  Settings,
  Tools,
  Youplan,
} from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Flash } from '@randstad-lean-mobile-factory/react-assets/dist/logos';
import ReactGA from 'react-ga4';
import {
  RandstadAppsPopup,
  Sidebar as SidebarPrimitive,
  SidebarItem,
} from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { MODAL_ROUTES, useBackgroundLocation } from 'src/ModalRoutes_DEPRECATED/ModalRoutes.types';
import {
  getAnalyticsUserInfo,
  getCurrentAgency,
  getCurrentBrandCode,
} from 'src/Redux/Perimeter/Selectors';
import { ROUTES } from 'src/Routes/Routes.types';
import { ParametersPopup } from './ParametersPopup';
import {
  appelMedicalBrandCodes,
  cdiiRepositioningAgencies,
  temporaryWorkersRepositioningAgencies,
} from './Sidebar.helpers';
import styles from './Sidebar.module.scss';
import { ANALYTICS_EVENT } from 'src/Services/Analytics';

export const Sidebar = () => {
  const navigate = useNavigate();
  const location = useBackgroundLocation();
  const currentSection = location.pathname.split('/').slice(0, 3).join('/');
  const userInfo = useSelector(getAnalyticsUserInfo);

  const { isProd } = useIsProductionEnvironment();

  const currentBrandCode = useSelector(getCurrentBrandCode);
  const currentAgency = useSelector(getCurrentAgency);

  const isMedicalAgency = useMemo(() => appelMedicalBrandCodes.includes(currentBrandCode || ''), [
    currentBrandCode,
  ]);

  const enableCdii = useMemo(
    () => !isProd || cdiiRepositioningAgencies.includes(currentAgency ?? ''),
    [isProd, currentAgency]
  );

  const enableTemporaryWorkers = useMemo(
    () => !isProd || temporaryWorkersRepositioningAgencies.includes(currentAgency ?? ''),
    [isProd, currentAgency]
  );

  const sections = useMemo(
    () =>
      isMedicalAgency
        ? [
            {
              title: 'demandes externes',
              items: [
                {
                  Icon: List,
                  title: 'liste demandes',
                  selected: currentSection === ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({}),
                  onClick: () => navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_LIST.buildPath({})),
                },
                {
                  Icon: Parameters,
                  title: 'correspondances',
                  selected:
                    currentSection === ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({}),
                  onClick: () =>
                    navigate(ROUTES.EXTERNAL_DEMANDS.DEMANDS_PREFERENCES.buildPath({})),
                },
              ],
            },
          ]
        : [
            {
              title: "suivi d'activité",
              items: [
                {
                  Icon: Megaphone,
                  title: 'commandes',
                  selected: currentSection === ROUTES.ACTIVITY.MISSIONS.buildPath({}),
                  onClick: () => navigate(ROUTES.ACTIVITY.MISSIONS.buildPath({})),
                },
                {
                  Icon: License,
                  title: 'contrats',
                  selected: currentSection === ROUTES.ACTIVITY.CONTRACTS.buildPath({}),
                  onClick: () => navigate(ROUTES.ACTIVITY.CONTRACTS.buildPath({})),
                },
                {
                  Icon: Youplan,
                  title: 'youplan',
                  selected: currentSection === ROUTES.ACTIVITY.YOUPLAN.buildPath({}),
                  onClick: () => navigate(ROUTES.ACTIVITY.YOUPLAN.buildPath({})),
                },
              ],
            },
            {
              title: 'planning de mission',
              items: [
                enableCdii && {
                  Icon: People,
                  title: 'cdii',
                  selected: currentSection === ROUTES.TALENTS.CDII.buildPath({}),
                  onClick: () => navigate(ROUTES.TALENTS.CDII.buildPath({})),
                },
                enableTemporaryWorkers && {
                  Icon: Profil,
                  title: 'intérimaires',
                  selected: currentSection === ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}),
                  onClick: () => {
                    navigate(ROUTES.TALENTS.TEMPORARY_WORKERS.buildPath({}));
                    ReactGA.event(ANALYTICS_EVENT.CLICK_TALENT_SCREEN, {
                      ...userInfo,
                    });
                  },
                },
              ],
            },
            {
              title: 'accidentologie',
              items: [
                {
                  Icon: Analysis,
                  title: 'analyse at',
                  selected: currentSection === ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({}),
                  onClick: () => navigate(ROUTES.ACCIDENTOLOGY.WORK_ACCIDENT.buildPath({})),
                },
                {
                  Icon: Action,
                  title: "plans d'action",
                  selected: currentSection === ROUTES.ACCIDENTOLOGY.ACTION_PLAN.buildPath({}),
                  onClick: () => navigate(ROUTES.ACCIDENTOLOGY.ACTION_PLAN.buildPath({})),
                },
              ],
            },
          ],
    [currentSection, enableCdii, enableTemporaryWorkers, isMedicalAgency, navigate, userInfo]
  );

  return (
    <span data-theme="dark" style={{ display: 'contents' }}>
      <SidebarPrimitive AppLogo={Flash} appName="flash" sections={sections}>
        {!isMedicalAgency && (
          <SidebarItem
            Icon={Tools}
            title="outils"
            onClick={() =>
              navigate(MODAL_ROUTES.VARIABLE_SIMULATOR.route.buildPath({}), {
                state: { background: location },
              })
            }
          />
        )}
        <RandstadAppsPopup
          isProd={isProd}
          brandCode={currentBrandCode}
          trigger={<SidebarItem Icon={InterApp} title="applications" />}
          triggerClassName={styles.trigger}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        />
        <ParametersPopup
          trigger={<SidebarItem Icon={Settings} title="paramètres" />}
          triggerClassName={styles.trigger}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        />
      </SidebarPrimitive>
    </span>
  );
};
