import React from 'react';
import classNames from 'classnames';

import { Props } from './YouplanMissionBadge.types';
import styles from './YouplanMissionBadge.module.scss';
import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import {
  EnumYouplanMissionActionSummaryAction,
  EnumYouplanMissionActionSummaryLastConflictCause,
} from 'src/Services/API';

const ActionLabels: Record<
  EnumYouplanMissionActionSummaryAction | 'PENDING' | 'CANCELLED',
  string
> = {
  [EnumYouplanMissionActionSummaryAction.CONTRACT_RENEWAL]: 'transformé en prolongation',
  [EnumYouplanMissionActionSummaryAction.MISSION_CREATION]: 'transformé en commande',
  [EnumYouplanMissionActionSummaryAction.MARKED_AS_READ]: 'transformé en planification',
  PENDING: 'en attente de traitement',
  CANCELLED: 'annulé par le client',
};

const ConflictsLabel: Record<EnumYouplanMissionActionSummaryLastConflictCause, string> = {
  [EnumYouplanMissionActionSummaryLastConflictCause.CANCELLED]: 'annulé par le client',
  [EnumYouplanMissionActionSummaryLastConflictCause.MODIFIED]: 'modifié par le client',
  [EnumYouplanMissionActionSummaryLastConflictCause.REJECTED]: 'refusé par le candidat',
};

const YouplanMissionBadge = ({ status, isCancelled, isOkOk }: Props) => {
  return (
    <BadgeDeprecated
      value={
        status?.isInConflict || isCancelled
          ? ConflictsLabel[
              isCancelled
                ? EnumYouplanMissionActionSummaryLastConflictCause.CANCELLED
                : status?.lastConflictCause ??
                  EnumYouplanMissionActionSummaryLastConflictCause.CANCELLED
            ]
          : ActionLabels[isCancelled ? 'CANCELLED' : status?.action ?? 'PENDING']
      }
      className={classNames({
        [styles.done]: status?.action !== undefined,
        [styles.conflict]:
          status?.isInConflict ||
          (status !== undefined &&
            status.action !== EnumYouplanMissionActionSummaryAction.MARKED_AS_READ &&
            isCancelled),
        [styles.cancelled]:
          (status === undefined ||
            status.action === EnumYouplanMissionActionSummaryAction.MARKED_AS_READ) &&
          isCancelled,
        [styles.pendingConflict]:
          status?.isInConflict &&
          !isOkOk &&
          status.action === EnumYouplanMissionActionSummaryAction.MISSION_CREATION,
      })}
    />
  );
};

export default YouplanMissionBadge;
