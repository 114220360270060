import { TALENTS_STATUS } from '../TemporaryWorkers.types';

export const getTemporaryWorkerStatusColor = (status: TALENTS_STATUS) => {
  switch (status) {
    case TALENTS_STATUS.IN_MISSION:
      return 'success';
    case TALENTS_STATUS.INTERMISSION:
      return 'error';
    case TALENTS_STATUS.SOON_AVAILABLE:
      return 'warning';
    case TALENTS_STATUS.ABSENT:
      return 'navy';
  }
};
