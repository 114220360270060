import throttle from 'lodash.throttle';
import { useMemo, useState } from 'react';
import { useFetchAgencyCandidates } from 'src/Hooks/Candidate/useFetchAgencyCandidates';
import { FETCH_STATUS } from 'src/Redux/Types';
import { toFetchStatus } from 'src/Services/Async';
import { Props } from './SelectCandidate.types';
import { ComboBox } from '@randstad-lean-mobile-factory/react-components-core';

const SelectCandidate = ({
  selectedCandidate,
  setSelectedCandidate,
  canBeReset,
  agency,
  brandCode,
}: Props) => {
  const [keyword, setKeyword] = useState('');
  const { data, isSuccess, isError, isLoading } = useFetchAgencyCandidates(
    keyword,
    agency,
    brandCode
  );
  const throttledSetKeyword = useMemo(() => throttle(setKeyword, 500), [setKeyword]);
  return (
    <ComboBox
      id="SelectCandidate"
      disableClearable={!canBeReset}
      placeholder="3 caractères minimum"
      value={selectedCandidate}
      onSearch={keyword => throttledSetKeyword(keyword)}
      searchResults={data?.candidates ?? []}
      keyValueExtractor={({ candidateId, firstName, name }) => ({
        key: candidateId ?? 'unknown',
        value: firstName && name ? `${firstName} ${name}` : '',
      })}
      onChange={searchResult => {
        setSelectedCandidate(searchResult ?? undefined);
      }}
      fetchStatus={
        keyword.length > 0
          ? toFetchStatus({ isError, isLoading, isSuccess })
          : FETCH_STATUS.FULFILLED
      }
      minLengthToSearch={3}
    />
  );
};

export default SelectCandidate;
