import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  CountCommandsParams,
  CountCommandsResponse,
  ExternalDemandsService,
} from 'src/Services/API';
import { MutationKeys } from '../types';

export const useCountExternalDemands = (
  extraOptions?: UseMutationOptions<CountCommandsResponse, unknown, CountCommandsParams, unknown>
) => {
  return useMutation(
    [MutationKeys.countExternalDemands],
    async (params: CountCommandsParams) => {
      return await ExternalDemandsService.externalDemandsControllerCountExternalDemands({
        body: params,
      });
    },
    {
      ...extraOptions,
    }
  );
};
