import { CDII_STATUS } from 'src/Routes/Talents/Cdii/Cdii.types';
import { Qualification } from 'src/Services/API';

export enum FilterInterval {
  SEVEN = 'seven',
  FIFTEEN = 'fifteen',
}

export interface TalentFilterState {
  currentQualifications?: Qualification[];
  currentName?: string;
  currentStatuses?: CDII_STATUS[];
  currentInterval: FilterInterval;
}
