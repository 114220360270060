import { useDispatch, useSelector } from 'react-redux';
import { Props } from './FilterTalentsModal.enhanced.types';
import React, { useCallback } from 'react';
import { RootState } from 'src/Redux/RootReducer';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { Qualification } from 'src/Services/API';
import {
  getCurrentInterval,
  getCurrentQualifications,
  getCurrentStatuses,
} from 'src/Redux/TemporaryWorkers/Selector';
import { talentFilterActions } from 'src/Redux/TemporaryWorkers/Slice';
import { CDII_STATUS } from '../../Cdii/Cdii.types';
import { FilterInterval } from 'src/Redux/TemporaryWorkers/Types';
import FilterTalentsModal from './FilterTalentsModal.component';

const MemoFilterTalentsModal = React.memo(FilterTalentsModal);

const EnhancedFilterTalentsModal = ({ isOpen, onClose, selectedSource }: Props) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, any, AnyAction>>();

  // Qualification Selection
  const selectedQualifications = useSelector(getCurrentQualifications);
  const setSelectedQualifications = useCallback(
    (qualifications?: Qualification[]) =>
      dispatch(talentFilterActions.setCurrentQualifications(qualifications)),
    [dispatch]
  );

  // Status Selection
  const selectedStatuses = useSelector(getCurrentStatuses);
  const setSelectedStatuses = useCallback(
    (statuses: CDII_STATUS[] | undefined) =>
      dispatch(talentFilterActions.setCurrentStatuses(statuses)),
    [dispatch]
  );

  // Interval Selection
  const selectedInterval = useSelector(getCurrentInterval);
  const setSelectedInterval = useCallback(
    (interval: FilterInterval) => dispatch(talentFilterActions.setCurrentInterval(interval)),
    [dispatch]
  );
  return (
    <MemoFilterTalentsModal
      selectedQualifications={selectedQualifications}
      setSelectedQualifications={setSelectedQualifications}
      selectedStatuses={selectedStatuses}
      setSelectedStatuses={setSelectedStatuses}
      selectedInterval={selectedInterval}
      setSelectedInterval={setSelectedInterval}
      onValidateClick={onClose}
      onCancelClick={onClose}
      open={isOpen}
      selectedSource={selectedSource}
    />
  );
};

export default EnhancedFilterTalentsModal;
