import { useCountExternalDemands } from '../../Hooks/ExternalDemands/useCountExternalDemands';
import { Badge } from '@randstad-lean-mobile-factory/react-components-core';
import classnames from 'classnames';
import { Props } from './ExternalDemandsListCounterBadge.types';
import { useSelector } from 'react-redux';
import { getCurrentAgency } from '../../Redux/Perimeter/Selectors';
import { Loading } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import styles from './ExternalDemandsListCounterBadge.module.scss';
import { useEffect } from 'react';

export const ExternalDemandsListCounterBadge = ({ source, displayStatus, isSelected }: Props) => {
  const agencyId = useSelector(getCurrentAgency) ?? '';

  const { mutate, data, isSuccess } = useCountExternalDemands();

  useEffect(() => {
    mutate({ source, displayStatus, agencyId });
  }, [source, displayStatus, mutate, agencyId]);

  return isSuccess ? (
    <Badge
      color={'blue'}
      className={classnames(styles.badge, isSelected && styles.selectedBadge)}
    >{`${data?.count}`}</Badge>
  ) : (
    <Loading variant="line" />
  );
};
