import React, { useEffect, useState } from 'react';

import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import {
  Balloon,
  BinocularFullCut,
  Checkerboard,
  PointingFingerCut,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  getSelectedCompany,
  getSelectedQualification,
  isValidPreviousPositionStudySelected,
} from 'src/Redux/MissionCreation/Selectors';
import { MissionCreationLocationState } from 'src/Services/Routing';
import FirstContent from './FirstContent';
import { MISSION_CREATION_STEP, MISSION_CREATION_WAY, Props } from './MissionCreationModal.types';
import MissionCreationSuccess from './MissionCreationSuccess';
import MissionCreationValidation from './MissionCreationValidation';
import NewMissionConfirmation from './NewMissionConfirmation';
import PreviousMissions from './PreviousMissions';
import PreviousPositionStudies from './PreviousPositionStudies';
import SecondContent from './SecondContent';
import { usePatchCompanyQualification } from 'src/Hooks/Companies/usePatchCompanyQualication';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from 'src/Hooks/types';
import { Transfert } from '@randstad-lean-mobile-factory/react-assets/dist/headers';

const CreationWay = {
  [MISSION_CREATION_WAY.FROM_NEW_DATA]: {
    next: (props: Props) => {
      if (props.positionStudiesCount > 0) {
        return MISSION_CREATION_STEP.NEW_DATA_SELECTED_VALIDATION;
      } else {
        return MISSION_CREATION_STEP.NEW_DATA_SELECTION;
      }
    },
    onNext: (props: Props) => {
      if (props.positionStudiesCount === 0) {
        props.searchCompanyInfo();
      }
    },
  },
  [MISSION_CREATION_WAY.FROM_EDP]: {
    next: () => MISSION_CREATION_STEP.EDP,
    onNext: (props: Props) => {
      props.searchPreviousPositionStudies();
    },
  },
  [MISSION_CREATION_WAY.FROM_OLD_MISSION]: {
    next: () => {
      return MISSION_CREATION_STEP.OLD_DATA;
    },
    onNext: (props: Props) => {
      props.searchPreviousMissions();
    },
  },
};

interface ModalChild {
  component: React.ReactNode;
  onPrevious?: (props: Props) => void;
  onNext?: (props: Props) => void;
  next: (props: Props) => MISSION_CREATION_STEP | undefined;
  isValid: (props: Props) => boolean;
  previous: (props: Props) => MISSION_CREATION_STEP | undefined;
  title: string | ((props: Props) => string);
  icon: React.ReactNode | ((props: Props) => React.ReactNode);
  rightButtonText?: {
    primary: string;
    secondary: string;
  };
}

const titleNoEDPForPilot = `Aucune EDP à jour. Vous allez être redirigé vers l'application EDP pour créer ou mettre à jour une EDP.`;

const ModalChildren: Record<MISSION_CREATION_STEP, ModalChild> = {
  [MISSION_CREATION_STEP.COMPANY_QUALIFICATION_SELECTION]: {
    component: <FirstContent shouldChooseCreationWay={false} />,
    next: (props: Props) =>
      props.isPilotUnit ? MISSION_CREATION_STEP.EDP : MISSION_CREATION_STEP.CREATION_WAY_SELECTION,
    previous: () => {
      return undefined;
    },
    onNext: (props: Props) => {
      props.isPilotUnit && CreationWay[MISSION_CREATION_WAY.FROM_EDP].onNext(props);
    },
    isValid: (props: Props) => props.isValidCompanyQualificationCouple,
    title: 'Passer une commande - 1/2',
    icon: <PointingFingerCut />,
    rightButtonText: { primary: 'suivant', secondary: 'annuler' },
  },
  [MISSION_CREATION_STEP.CREATION_WAY_SELECTION]: {
    component: <FirstContent shouldChooseCreationWay />,
    next: (props: Props) =>
      CreationWay[props.selectedCreationWay ?? MISSION_CREATION_WAY.FROM_NEW_DATA].next(props),
    previous: () => MISSION_CREATION_STEP.COMPANY_QUALIFICATION_SELECTION,
    onPrevious: (props: Props) => {
      props.resetSelectedCreationWay();
    },
    onNext: (props: Props) =>
      CreationWay[props.selectedCreationWay ?? MISSION_CREATION_WAY.FROM_NEW_DATA].onNext(props),
    isValid: (props: Props) => props.isValidCreationWay,
    title: 'Passer une commande - 1/2',
    icon: <PointingFingerCut />,
    rightButtonText: { primary: 'suivant', secondary: 'précédent' },
  },
  [MISSION_CREATION_STEP.NEW_DATA_SELECTED_VALIDATION]: {
    component: <NewMissionConfirmation />,
    next: () => MISSION_CREATION_STEP.NEW_DATA_SELECTION,
    previous: () => MISSION_CREATION_STEP.EDP,
    isValid: () => true,
    onNext: (props: Props) => {
      props.searchCompanyInfo();
    },
    onPrevious: (props: Props) => {
      props.searchPreviousPositionStudies();
      props.searchCompanyInfo();
    },
    title: 'validation',
    icon: <BinocularFullCut />,
    rightButtonText: { primary: 'oui, je valide', secondary: "partir d'une EDP" },
  },
  [MISSION_CREATION_STEP.OLD_DATA]: {
    component: <PreviousMissions />,
    next: () => MISSION_CREATION_STEP.NEW_DATA_SELECTION,
    previous: () => MISSION_CREATION_STEP.CREATION_WAY_SELECTION,
    onPrevious: (props: Props) => {
      props.resetSelectedPreviousMission();
      props.resetSelectedCreationWay();
    },
    onNext: (props: Props) => {
      props.setSelectedPreviousMissionDetails();
      props.searchCompanyInfo();
    },
    isValid: (props: Props) => props.isValidPreviousMissionSelected,
    title: "choix d'une ancienne commande",
    icon: <BinocularFullCut />,
    rightButtonText: { primary: 'suivant', secondary: 'précédent' },
  },
  [MISSION_CREATION_STEP.EDP]: {
    component: <PreviousPositionStudies />,
    next: (props: Props) =>
      props.isPilotUnit && props.isNonePreviousStudiesCompleted
        ? undefined
        : MISSION_CREATION_STEP.NEW_DATA_SELECTION,
    previous: (props: Props) =>
      props.isPilotUnit
        ? MISSION_CREATION_STEP.COMPANY_QUALIFICATION_SELECTION
        : MISSION_CREATION_STEP.CREATION_WAY_SELECTION,
    onPrevious: (props: Props) => {
      props.resetSelectedPreviousPositionStudy();
      props.resetSelectedCreationWay();
    },
    onNext: (props: Props) => {
      props.setSelectedPreviousPositionStudyForCreation();
      props.searchCompanyInfo();
    },
    isValid: (props: Props) => props.isValidPreviousPositionStudySelected,
    title: (props: Props) => {
      return props.isNonePreviousStudiesCompleted
        ? titleNoEDPForPilot
        : "choix d'une étude de poste";
    },
    icon: (props: Props) => {
      return props.isNonePreviousStudiesCompleted ? <Transfert /> : <BinocularFullCut />;
    },
    rightButtonText: { primary: 'suivant', secondary: 'précédent' },
  },
  [MISSION_CREATION_STEP.NEW_DATA_SELECTION]: {
    component: <SecondContent />,
    next: () => MISSION_CREATION_STEP.VALIDATION,
    previous: (props: Props) =>
      props.isPilotUnit ? MISSION_CREATION_STEP.EDP : MISSION_CREATION_STEP.CREATION_WAY_SELECTION,
    onPrevious: (props: Props) => props.resetProcessedMission(),
    isValid: (props: Props) => props.isValidProcessedMission,
    title: 'Passer une commande - 2/2',
    icon: <PointingFingerCut />,
    rightButtonText: { primary: 'suivant', secondary: 'précédent' },
  },
  [MISSION_CREATION_STEP.VALIDATION]: {
    component: <MissionCreationValidation />,
    next: () => MISSION_CREATION_STEP.END,
    previous: (props: Props) =>
      props.isFromYouplan
        ? MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY
        : MISSION_CREATION_STEP.NEW_DATA_SELECTION,
    onNext: (props: Props) => {
      props.isFromYouplan
        ? props.createMIssionWithoutScheduleFromYouplan()
        : props.createMissionWithoutSchedule();
    },
    isValid: () => true,
    title: 'récapitulatif de la commande',
    icon: <Checkerboard />,
    rightButtonText: { primary: 'valider', secondary: 'précédent' },
  },
  [MISSION_CREATION_STEP.END]: {
    component: <MissionCreationSuccess />,
    next: () => undefined,
    previous: () => undefined,
    isValid: () => true,
    title: '',
    icon: <Balloon />,
  },
  [MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY]: {
    component: <SecondContent />,
    next: () => MISSION_CREATION_STEP.VALIDATION,
    previous: () => undefined,
    isValid: (props: Props) => props.isValidProcessedMission,
    title: 'Passer une commande',
    icon: <PointingFingerCut />,
    rightButtonText: { primary: 'valider', secondary: 'annuler' },
  },
};

const MissionCreationModal = (props: Props) => {
  const { onClose, setIsFromYouplan, setIsFromMyRegion, setIsTalent } = props;
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedQualification = useSelector(getSelectedQualification);
  const addPotentialQualificationToCompany = usePatchCompanyQualification();
  const modalParameters = (useLocation().state as
    | MissionCreationLocationState
    | undefined
    | undefined)?.modalParameters;
  const isFromEDP = useSelector(isValidPreviousPositionStudySelected);
  const [step, setStep] = useState(
    modalParameters?.openingStep ?? MISSION_CREATION_STEP.COMPANY_QUALIFICATION_SELECTION
  );

  useEffect(() => {
    if (isFromEDP) {
      props.setSelectedPreviousPositionStudyForCreation();
      props.searchCompanyInfo();
      setStep(MISSION_CREATION_STEP.NEW_DATA_SELECTION);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalParameters?.openingStep === MISSION_CREATION_STEP.FROM_YOUPLAN_ENTRY) {
      setIsFromYouplan();
    }
  }, [setIsFromYouplan, modalParameters?.openingStep]);

  useEffect(() => {
    if (modalParameters?.isFromMyRegion) {
      setIsFromMyRegion();
    }
  }, [modalParameters?.isFromMyRegion, setIsFromMyRegion]);

  useEffect(() => {
    if (modalParameters?.isTalent) {
      setIsTalent();
    }
  }, [modalParameters?.isTalent, setIsTalent]);

  const queryClient = useQueryClient();

  const {
    component,
    next,
    previous,
    isValid,
    title,
    icon,
    rightButtonText,
    onPrevious,
    onNext,
  } = ModalChildren[step];
  const nextStep = next(props);
  const previousStep = previous(props);

  const renderIcon = (
    icon: React.ReactNode | ((props: Props) => React.ReactNode),
    props: Props
  ) => {
    if (typeof icon === 'function') {
      return icon(props);
    }
    return icon;
  };

  const renderTitle = (title: string | ((props: Props) => string), props: Props) => {
    if (typeof title === 'function') {
      return title(props);
    }
    return title;
  };

  return (
    <ModalDeprecated
      open={props.open ?? true}
      title={renderTitle(title, props)}
      icon={renderIcon(icon, props)}
      key={'key'}
      nested
      onClose={() => {
        onClose({ success: false });
        setStep(MISSION_CREATION_STEP.COMPANY_QUALIFICATION_SELECTION);
      }}
      footerActionsLeft={
        previousStep !== undefined
          ? [<Button.Tertiary onClick={() => onClose({ success: false })}>annuler</Button.Tertiary>]
          : []
      }
      footerActionsRight={
        nextStep !== undefined
          ? [
              <Button.Secondary
                onClick={() => {
                  previousStep !== undefined ? setStep(previousStep) : onClose({ success: false });
                  onPrevious?.(props);
                }}
              >
                {rightButtonText?.secondary}
              </Button.Secondary>,
              <Button
                disabled={!isValid(props)}
                onClick={() => {
                  isValid(props) && setStep(nextStep);
                  onNext?.(props);
                  if (nextStep === MISSION_CREATION_STEP.END) {
                    addPotentialQualificationToCompany.mutate({
                      companyId: selectedCompany?.companyId ?? '',
                      potentialQualificationId: selectedQualification?.id ?? '',
                    });
                  }
                }}
              >
                {rightButtonText?.primary}
              </Button>,
            ]
          : props.isPilotUnit && props.isNonePreviousStudiesCompleted
          ? []
          : [
              <Button.Secondary
                onClick={() => {
                  queryClient.invalidateQueries([QueryKeys.fetchMissions]);
                  props.redirectOnMission();
                }}
              >
                visualiser la commande
              </Button.Secondary>,
              <Button
                onClick={() => {
                  queryClient.invalidateQueries([QueryKeys.fetchMissions]);
                  onClose({ success: true });
                }}
              >
                continuer sur Flash
              </Button>,
            ]
      }
    >
      {component}
    </ModalDeprecated>
  );
};

export default MissionCreationModal;
