import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { TopBar } from 'src/Components/TopBar';
import { ROUTES } from 'src/Routes/Routes.types';
import { CdiiDetailScreen } from 'src/Screens/CdiiDetail';
import styles from './CdiiDetail.module.scss';

export const CdiiDetailRoute = () => {
  const { candidateId } = useTypedParams(ROUTES.TALENTS.CDII.CDII_DETAIL);

  return (
    <div className={styles.container}>
      <TopBar title="détails talent" goBackTo={ROUTES.TALENTS.CDII.buildPath({})} />
      <div className={styles.detail}>
        <CdiiDetailScreen candidateId={candidateId} statut="" />
      </div>
    </div>
  );
};
